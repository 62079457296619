import * as React from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import { useSelector } from 'react-redux'

import { useNavigate } from '@reach/router'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useState } from 'react'

import ApiLink from './ApiLink'

const theme = createTheme()
const ViewProfile = () => {
  const [userData, setUserData] = useState({
    id: '',
    firstName: '',
    lastName: '',
    avatar: '',
  })
  const [showSpinner, setShowSpinner] = useState(true)
  const navigate = useNavigate()
  const state = useSelector((state) => state.user)

  useEffect(() => {
    if (!state.user) {
      navigate(`/signIn`)
    }
    async function fetchProfile() {
      fetch(`${ApiLink}/myAccount/${state.user.email}`)
        .then((res) => res.json())
        .then((data) => {
          setShowSpinner(false)
          setUserData(data.profileData)
        })
    }
    fetchProfile()
  }, [])
  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              boxShadow: 3,
              padding: 2,
            }}
          >
            <Typography component="h1" variant="h5">
              {showSpinner === true ? (
                <CircularProgress color="secondary" style={{ height: '200px', width: '200px' }} />
              ) : (
                <>
                  {state.user != null && (
                    <Box sx={{ mt: 3 }}>
                      <Grid container spacing={10}>
                        <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                          <img
                            src={
                              userData.avatar === ''
                                ? 'https://res.cloudinary.com/dot-pic/image/upload/v1659617513/Avatar_3_zaxygb.png'
                                : userData.avatar
                            }
                            style={{
                              width: '200px',
                              borderRadius: '30px',
                            }}
                            alt="avatar"
                          ></img>
                        </Grid>
                        <Grid item md={8} xs={12}>
                          <Grid item xs={12}>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="firstName"
                              label="First Name"
                              name="firstName"
                              autoComplete="userName"
                              disabled
                              defaultValue={userData.firstName}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="lastName"
                              label="Last Name"
                              name="lastName"
                              autoComplete="lastName"
                              disabled
                              defaultValue={userData.lastName}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="userName"
                              label="User Name"
                              name="userName"
                              autoComplete="userName"
                              disabled
                              defaultValue={state.user.userName}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="email"
                              label="Email Address"
                              name="email"
                              autoComplete="email"
                              disabled
                              defaultValue={state.user.email}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <>
                        <div className="text-center">
                          <Button
                            onClick={() => {
                              navigate('/editProfile')
                            }}
                            type="submit"
                            size="large"
                            variant="contained"
                            sx={{
                              mt: 3,
                              mb: 2,

                              fontSize: '16px',
                              width: '200px',
                              height: '40px',
                              background: '#4285f4',
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                      </>
                    </Box>
                  )}
                </>
              )}
            </Typography>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  )
}
export default ViewProfile
