const MapLoader = () => {
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        backgroundColor: 'black',
        width: '100%',
        height: '100vh',
        zIndex: 999,
      }}
    >
      <video width={'100%'} height={'100%'} autoPlay muted>
        <source src={`videos/maploader.mp4`} type="video/mp4" style={{ objectFit: 'fill', width: '100vw' }} />
      </video>
    </div>
  )
}

export default MapLoader
