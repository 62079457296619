import { React, useCallback, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { Box, Button, Divider, Grid, TextField } from '@mui/material'
// import CertificateTab from './CertificateTab'
import nft from '../../../assets/images/collection-nft.png'
import { Col, Form, Row } from 'react-bootstrap'
// import star from '../../../assets/images/star.png'
import StreetView from '../../components/mapbox/streetView'
import CloseIcon from '@mui/icons-material/Close'
import { ethers } from 'ethers'
import PreviewImage from './PreviewImage'
import MarketABI from '../../../contract/marketplace.json'
import ABI from '../../../contract/GameRee1155.json'
import USDG from '../../../contract/USDG.json'
import ConfigABI from '../../../contract/Config.json'
import { loadProvider } from '../../../helpers'
import { config_addr, USDG_addr, market_addr, NFT_addr } from '../../../contract/addresses'
import CustomDialog from '../../Dialog'
import { Clear, ErrorOutline, LocalOffer } from '@mui/icons-material'
import { toast } from 'react-toastify'
import apis from '../../../services'
import Geocode from 'react-geocode'
import ApiLink from '../../pages/ApiLink'

function Resell({ isOpen, setIsOpen }) {
  return (
    <>
      <div className={'resell-main primary-scroll ' + (isOpen ? 'resell-show' : '')}>
        <div className="dialog-close-header mb-3">
          <CloseIcon onClick={() => setIsOpen(false)} />
        </div>
        <Form>
          <Form.Group className="form-group custom-primary-group" controlId="original_price">
            <Form.Label>Original Price</Form.Label>
            <Form.Control value={'0,000'} />
          </Form.Group>
          <Form.Group className="form-group custom-primary-group" controlId="price">
            <Form.Label>Price set to be</Form.Label>
            <Form.Control value={'0,000'} />
          </Form.Group>
          <Form.Group className="form-group custom-primary-group" controlId="discount">
            <Form.Label>Discount</Form.Label>
            <Form.Control value={'0,000'} />
          </Form.Group>
          <Form.Group className="form-group custom-primary-group" controlId="percentage">
            <Form.Label>User Percentage</Form.Label>
            <Form.Control value={'20'} />
          </Form.Group>
          <Form.Group className="form-group custom-primary-group" controlId="fee">
            <Form.Label>Platform Fee</Form.Label>
            <Form.Control value={'20'} />
          </Form.Group>
          <Form.Group className="form-group d-flex justify-content-evenly w-100">
            <button type="button" className="custom-btn light-primary-btn" onClick={() => setIsOpen(false)}>
              Save
            </button>
            <button type="button" className="custom-btn danger-btn" onClick={() => setIsOpen(false)}>
              Cancel
            </button>
          </Form.Group>
        </Form>
      </div>
    </>
  )
}

function AssetProfile({ isOpen, setIsOpen, b_data }) {
  return (
    <>
      <div className={'asset-profile primary-scroll ' + (isOpen ? 'resell-show' : '')}>
        <div className="dialog-close-header">
          <CloseIcon onClick={() => setIsOpen(false)} />
        </div>
        <div className="main-asset overflow-auto primary-scroll">
          <Row>
            <Col lg={4} md={4}>
              <img src={b_data['image']} className="nft-img" alt="" />
            </Col>
            <Col lg={8} md={8}>
              <Form className="custom-form-main row-col-text">
                <Form.Group className="form-group custom-primary-group" controlId="roadname">
                  <Form.Label>Road Name:</Form.Label>
                  <Form.Control value={b_data['roadName']} />
                </Form.Group>
                <Form.Group className="form-group custom-primary-group" controlId="post_code">
                  <Form.Label>Post Code:</Form.Label>
                  <Form.Control value={b_data['postCode']} />
                </Form.Group>
                <Form.Group className="form-group custom-primary-group" controlId="street_name">
                  <Form.Label>Street Name:</Form.Label>
                  <Form.Control value={b_data['streetName']} />
                </Form.Group>
                <Form.Group className="form-group custom-primary-group" controlId="country">
                  <Form.Label>Country:</Form.Label>
                  <Form.Control value={'not_available'} />
                </Form.Group>
                <Form.Group className="form-group custom-primary-group" controlId="units">
                  <Form.Label>No. of units:</Form.Label>
                  <Form.Control value={b_data['noOfUnits']} />
                </Form.Group>
                <Form.Group className="form-group custom-primary-group" controlId="gross">
                  <Form.Label>No. of total gross:</Form.Label>
                  <Form.Control value={'not_available'} />
                </Form.Group>
                <Form.Group className="form-group custom-primary-group" controlId="BUSDG">
                  <Form.Label>Price BUSDG:</Form.Label>
                  <Form.Control value={'not_available'} />
                </Form.Group>
                <Form.Group className="form-group custom-primary-group" controlId="nft_price">
                  <Form.Label>NFT Price $:</Form.Label>
                  <Form.Control value={b_data['currentNFT_Price']} />
                </Form.Group>
                <Form.Group className="form-group custom-primary-group" controlId="asset_price">
                  <Form.Label>Real world asset price:</Form.Label>
                  <Form.Control value={'not_available'} />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

function Offer({ isOpen, setIsOpen }) {
  return (
    <>
      <div className={'asset-profile primary-scroll ' + (isOpen ? 'resell-show' : '')}>
        <div className="dialog-close-header mb-3">
          <CloseIcon onClick={() => setIsOpen(false)} />
        </div>
        <div className="main-asset overflow-auto primary-scroll">
          <Row>
            <Col lg={4} md={4}>
              <img src={nft} className="nft-img" alt="" />
            </Col>
            <Col lg={8} md={8}>
              <Form>
                <div className="custom-form-main row-col-text">
                  <Form.Group className="form-group custom-primary-group" controlId="owner">
                    <Form.Label>Owner Name:</Form.Label>
                    <Form.Control value={'Oxf539..9x67'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="name">
                    <Form.Label>Building Name:</Form.Label>
                    <Form.Control value={'PLUTON'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="roadname">
                    <Form.Label>Road Name:</Form.Label>
                    <Form.Control value={'XAVIER'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="city">
                    <Form.Label>City:</Form.Label>
                    <Form.Control value={'NewYork'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="city">
                    <Form.Label>City:</Form.Label>
                    <Form.Control value={'NewYork'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="city">
                    <Form.Label>City:</Form.Label>
                    <Form.Control value={'NewYork'} />
                  </Form.Group>
                </div>

                <Form.Group className="group-flex-btn mt-3">
                  <button type="button" className="custom-btn primary-btn m-0" onClick={() => setIsOpen(false)}>
                    Accept
                  </button>
                  <button type="button" className="custom-btn danger-btn-2" onClick={() => setIsOpen(false)}>
                    Decline
                  </button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
        <div className="main-asset overflow-auto primary-scroll">
          <Row>
            <Col lg={4} md={4}>
              <img src={nft} className="nft-img" alt="" />
            </Col>
            <Col lg={8} md={8}>
              <Form>
                <div className="custom-form-main row-col-text">
                  <Form.Group className="form-group custom-primary-group" controlId="owner">
                    <Form.Label>Owner Name:</Form.Label>
                    <Form.Control value={'Oxf539..9x67'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="name">
                    <Form.Label>Building Name:</Form.Label>
                    <Form.Control value={'PLUTON'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="roadname">
                    <Form.Label>Road Name:</Form.Label>
                    <Form.Control value={'XAVIER'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="city">
                    <Form.Label>City:</Form.Label>
                    <Form.Control value={'NewYork'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="city">
                    <Form.Label>City:</Form.Label>
                    <Form.Control value={'NewYork'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="city">
                    <Form.Label>City:</Form.Label>
                    <Form.Control value={'NewYork'} />
                  </Form.Group>
                </div>

                <Form.Group className="group-flex-btn mt-3">
                  <button type="button" className="custom-btn primary-btn m-0" onClick={() => setIsOpen(false)}>
                    Accept
                  </button>
                  <button type="button" className="custom-btn danger-btn-2" onClick={() => setIsOpen(false)}>
                    Decline
                  </button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

function Street({ isOpen, setIsOpen, position, mapBoxData }) {
  return (
    <>
      <div
      // className={
      //   "street-view-modal primary-scroll " + (isOpen ? "resell-show" : "")
      // }
      >
        <div className="street-header">
          <CloseIcon onClick={() => setIsOpen(false)} />
        </div>

        <StreetView address={mapBoxData} />
      </div>
    </>
  )
}

export default function DigitalCerfiticate({ status, toggleModal, data, position, refreshMapHandler, mapboxData }) {
  const [previewImage, setPreviewImage] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [tab, setTab] = useState('')
  const b_data = {}
  const googleMapsApiKey = 'AIzaSyA4bN_JLbgMsrsaspEm1ebHDiTNNvE7DTA'
  const [nftFiltered, setNftFiltered] = useState([])

  useEffect(() => {
    async function fetchData() {
      Geocode.setApiKey(googleMapsApiKey)
      Geocode.fromLatLng(position.lat, position.lng).then((response) => {
        fetch(`${ApiLink}/getAllNftPics`)
          .then((data) => data.json())
          .then((res) => {
            const nfts = res.nfts
            console.log(
              'filtered for address',
              nfts.filter((item) => item.address === response.results[0].formatted_address)
            )
            setNftFiltered(nfts.filter((item) => item.address === response.results[0].formatted_address))
            // setAllNFTPics(res.nfts) ;
          })
      })
    }
    fetchData()
  }, [])

  b_data['buildingName'] = data?.['building_name'] || ''
  b_data['buildingNumber'] = data?.['Building number'] || ''
  b_data['name'] = data?.['owner'] || ''
  b_data['noOfUnits'] = data?.['total_units'] || ''
  b_data['totalAreaInSqt'] = data?.['total_gross_area_sqt'] || ''
  b_data['city'] = data?.['city'] || ''
  b_data['currentNFT_Price'] = data?.['current_nft_price'] || ''
  b_data['priceInEuro'] = data?.['initial_price']
  b_data['image'] = data?.['image']
  b_data['owner'] = data?.['owner'] || ''
  b_data['postCode'] = data?.['postal_code']
  b_data['pricePerSquare'] = data?.['price_per_square'] || ''
  b_data['roadName'] = data?.['street_name']
  b_data['streetName'] = data?.['street_name']
  b_data['id'] = data?.['id'] || 0
  b_data['_account'] = data?.['account'] || ''
  var _account = b_data['_account']

  const [btnTxt, setBtnText] = useState('Not Listed')
  const [price, setPrice] = useState(0)
  const [modalStatus, setModalStatus] = useState(false)
  const [gameReeFee, setGameReeFee] = useState(0)
  const [saleLoader, setSaleLoader] = useState(false)

  const handleToggle = () => setModalStatus((prevState) => !prevState)

  const openTab = (e) => {
    setTab(e)
    setIsOpen(true)
  }

  const saleHandler = async () => {
    try {
      const signer = await loadProvider()
      toast.info('Sale Process Started')
      setSaleLoader(true)
      const NFTGameReeContract = new ethers.Contract(NFT_addr, ABI, signer)

      const tx = await NFTGameReeContract.setApprovalForAll(market_addr, true)
      const result = await tx.wait()

      console.log('APPROVAL FOR ALL', result)

      if (result.confirmations > 0) {
        const NFTMarketContract = new ethers.Contract(market_addr, MarketABI, signer)
        const r = await NFTMarketContract.createMarketItem(
          NFT_addr,
          data.id,
          ethers.utils.parseEther(price.toString()),
          0
        )
        await apis.updateMapNFT({ id: data.id, status: 'SALE' })
        toast.success('Sale Process is Completed')
        console.log('CREATE MARKET ITEM', r)

        toggleModal()
        handleToggle()
        refreshMapHandler()
      }
      setSaleLoader(false)
    } catch (error) {
      console.log(error)
      setSaleLoader(false)
      toast.error('Some error has occurred during sale process')
    }
  }
  const onSaleHandler = async () => {}
  const notListedHandler = () => {}

  const buyHandler = async () => {
    try {
      console.log('_ID_', data.id, typeof data.id)
      const signer = await loadProvider()
      const USDGContract = new ethers.Contract(USDG_addr, USDG, signer)
      const NFTGameReeContract = new ethers.Contract(NFT_addr, ABI, signer)
      const NFTMarketContract = new ethers.Contract(market_addr, MarketABI, signer)
      const ItemID = await NFTMarketContract.itemIDs_(data.id, NFT_addr)
      const itemData = await NFTMarketContract.idToMarketItem(ItemID)

      let price = itemData[5]

      console.log(itemData.toString())
      console.log(price.toString())
      let allowance = await USDGContract.allowance(_account, market_addr)
      console.log('ALLAOWANCE', allowance.toString())
      if (
        Number(ethers.utils.formatEther(allowance.toString())) >= Number(ethers.utils.formatEther(price.toString()))
      ) {
        console.log({ NFT_addr, ItemID })
        let tx = await NFTMarketContract.createMarketSale(NFT_addr, ItemID.toString())
        let trans = await tx.wait()
      } else {
        let approve = await USDGContract.approve(market_addr, price)
        let result = await approve.wait()
        console.log(result)

        if (result.confirmations > 0) {
          console.log({ NFT_addr, ItemID })
          let tx = await NFTMarketContract.createMarketSale(NFT_addr, ItemID)
          let trans = await tx.wait()
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  const getGameReeFee = useCallback(async () => {
    const signer = await loadProvider()
    const ConfigContract = new ethers.Contract(config_addr, ConfigABI, signer)
    const fee = await ConfigContract.nft_fee()
    setGameReeFee(fee.toString())
  }, [])
  const getTotalEarning = useCallback(
    (gameReeFee) => {
      const percentage = (gameReeFee / 100) * price
      return price - percentage
    },
    [price]
  )
  useEffect(() => {
    ;(async () => {
      await getGameReeFee()
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      try {
        const signer = await loadProvider()

        const NFTMarketContract = new ethers.Contract(market_addr, MarketABI, signer)
        const NFTGameReeContract = new ethers.Contract(NFT_addr, ABI, signer)

        const existInMarketPlace = await NFTMarketContract.exists(data.id, NFT_addr)
        const ownerAddress = await NFTGameReeContract.ownerOf(data.id)
        const owner_ = await NFTMarketContract.ownerOf(NFT_addr, data.id)

        const isOwner = ownerAddress === _account ? true : ownerAddress === market_addr ? owner_ === _account : false

        console.log({
          existInMarketPlace,
          isOwner,
          ownerAddress,
          _account,
          owner_,
        })

        if (isOwner && existInMarketPlace) {
          setBtnText('On Sale')
        } else if (isOwner && !existInMarketPlace) {
          setBtnText('Sale')
        } else if (!isOwner && existInMarketPlace) {
          setBtnText('Buy')
        } else {
          setBtnText('Not Listed')
        }
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  return (
    <>
      <div>
        <Dialog
          open={status}
          onClose={toggleModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          hideBackdrop={true}
          transitionDuration={0.5}
          maxWidth={'md'}
          className="certificate-modal"
        >
          {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
          <DialogContent sx={{ margin: 'auto' }} className="primary-scroll blur-bg">
            {/* <CertificateTab tab={tab} openTab={openTab} /> */}
            <div className="certificate-body">
              <div className="tag-box">
                <div className="title-box">
                  <h1>{b_data['buildingName']}</h1>
                  <h3>{b_data['streetName']}</h3>
                </div>
                <h3>YOU OWNED THIS PROPERTY</h3>
              </div>
              <img
                src={b_data['image']}
                className="building-img"
                onClick={() => setPreviewImage(true)}
                alt="NFTImage"
              />
              <Grid container spacing={0} className="building-detail">
                <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'rgba(7, 14, 30, 0.17)' }}>
                  <h1 className="title-1">OWNER OF THE PROPERTY</h1>
                  <h2 className="value">{b_data['owner']}</h2>
                </Grid>
                <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'rgba(7, 14, 30, 0.17)' }}>
                  <h1 className="title-2">USDG PRICE</h1>
                  <h2 className="value">{b_data['currentNFT_Price']}ETH</h2>
                </Grid>
                <Grid item xs={6}>
                  <h1 className="title-2">SQFT SIZE</h1>
                  <h2 className="value">{b_data['totalAreaInSqt']}</h2>
                </Grid>
              </Grid>
              <div className="button-box flex-container">
                {btnTxt === 'Sale' ? (
                  <button className="custom-btn primary-btn" onClick={handleToggle}>
                    {btnTxt}
                  </button>
                ) : btnTxt === 'On Sale' ? (
                  <button className="custom-btn primary-btn" onClick={onSaleHandler}>
                    {btnTxt}
                  </button>
                ) : btnTxt === 'Not Listed' ? (
                  <button className="custom-btn primary-btn" onClick={notListedHandler}>
                    {btnTxt}
                  </button>
                ) : btnTxt === 'Buy' ? (
                  <button className="custom-btn primary-btn" onClick={buyHandler}>
                    {btnTxt}
                  </button>
                ) : null}
              </div>
            </div>
            {tab === 'resell' ? (
              <Resell setIsOpen={setIsOpen} isOpen={isOpen} />
            ) : tab === 'asset' ? (
              <AssetProfile setIsOpen={setIsOpen} isOpen={isOpen} b_data={b_data} />
            ) : tab === 'offer' ? (
              <Offer setIsOpen={setIsOpen} isOpen={isOpen} />
            ) : (
              tab === 'street' && (
                <Street setIsOpen={setIsOpen} isOpen={isOpen} position={position} mapBoxData={mapboxData} />
              )
            )}
          </DialogContent>
        </Dialog>
        {/* TODO:: **********       SALE DIALOG     ************/}
        <CustomDialog status={modalStatus} toggleModal={handleToggle} title="Sales Price">
          <div className="modal-main-heading" style={{ display: 'flex' }}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '90%',
              }}
              variant="Top"
            >
              Sell Your Items
            </Box>
            <Box style={{ display: 'flex', width: '10%', cursor: 'pointer' }} onClick={handleToggle}>
              <Clear />
            </Box>
          </div>
          <Divider style={{ backgroundColor: 'gray' }} />
          <div
            style={{
              display: 'flex',
            }}
            className="modal-content"
          >
            <Grid container>
              <Grid item style={{ display: 'flex' }}>
                <Grid item>
                  <LocalOffer style={{ display: 'flex', justifyContent: 'start' }} />
                </Grid>
                Set Your Price
              </Grid>
            </Grid>
            <Divider style={{ height: '1em', backgroundColor: '#fffff' }} />
          </div>
          <div>
            <Grid container style={{ display: 'flex', flexDirection: 'column-reverse' }}>
              <Grid item container my={3}>
                <TextField
                  placeholder="Price"
                  label="Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  type="number"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Divider style={{ height: '1em', backgroundColor: '#fffff' }} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container>
              <Grid item>Fees</Grid>
              <Grid item>
                <ErrorOutline />
              </Grid>
            </Grid>
            <Grid container>
              <br />
              <div>
                <Grid item style={{ display: 'flex', fontSize: 'smaller' }}>
                  Platform Fee <Box component={'span'} width={'430px'} borderBottom={'1px dotted #808080'} />
                  {gameReeFee} %
                </Grid>
              </div>
              <Divider style={{ height: '1em', backgroundColor: '#fffff' }} />
            </Grid>

            <Divider style={{ height: '1em', backgroundColor: 'white' }} />
            <Grid container justifyContent="space-between">
              <Grid item>Total Earnings</Grid>
              <Grid item>
                <Grid item container flexDirection="column">
                  <Grid item>
                    <h5>{getTotalEarning(gameReeFee)} USDG</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <Grid item container justifyContent="center">
            <Button variant="outlined" color="secondary" onClick={saleHandler} disabled={saleLoader}>
              {saleLoader ? 'IN-PROCESS' : 'Complete Listing'}
            </Button>
          </Grid>
        </CustomDialog>
        <PreviewImage status={previewImage} toggleModal={setPreviewImage} image={b_data['image']} />
      </div>
    </>
  )
}
