async function getBuildingImage(name = '') {
  const _path = `${process.env.PUBLIC_URL}/img/buildings/${name}.png`
  return new Promise((resolve) => {
    var img = new Image()
    img.addEventListener('load', () => resolve(_path))
    img.addEventListener('error', () => resolve(`${process.env.PUBLIC_URL}/img/buildings/default.png`))
    img.src = _path
  })
}

export default getBuildingImage
