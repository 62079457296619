import { useState } from 'react'

function useNetworkList() {
  const [networklist, setNetworkList] = useState([
    { name: 'Ethereum Mainnet', chain_id: 1, hex: '0x1', token: 'ETH' },
    { name: 'BSC Mainnet', chain_id: 56, hex: '0x38', token: 'BNB' },
    { name: 'BSC Testnet', chain_id: 97, hex: '0x61', token: 'BNB' },
    { name: 'Rinkeby', chain_id: 4, hex: '0x4', token: 'ETH' },
    { name: 'Localhost', chain_id: 1337, hex: '0x539', token: 'ETH' },
  ])

  const find = (id) => networklist.find((network) => network.chain_id === id)

  return [networklist, setNetworkList, find]
}

export default useNetworkList
