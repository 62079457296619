import * as React from 'react'
import { Fragment, useCallback, useEffect, useState, useTransition } from 'react'
import { navigate } from '@reach/router'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import CertificateModal from '../../CertificateModal'

import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'

import { USDG_addr, uSDT_addr } from '../../../contract/addresses'
import TokenABI from '../../../contract/USDG.json'
import TokenMETADATA from '../../../contract/IERC20Metadata.json'

import Web3Modal from 'web3modal'
import { useSelector } from 'react-redux'
import { Skeleton } from '@mui/material'
import { DepositModal } from '../modals'
import { Dropdown } from 'react-bootstrap'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import changeNetwork from '../../../helpers/changeNetwork'
import { precisionRound } from '../../../helpers'
import WithdrawModal from '../modals/WithdrawModal'
import useNetworkList from '../../../hooks/useNetworkList'
import { toast } from 'react-toastify'

const mdTheme = createTheme({
  background: {
    default: '#fff',
  },
})

function DashboardContent(props) {
  const [balance, setBalance] = useState(0.0)
  const [tokenBalance, setTokenBalance] = useState(0.0)
  const [loader, setLoader] = useState(false)

  const [amountValue, setAmountValue] = useState(0)
  const [depositValue, setDepositValue] = useState(0)
  const [decimals, setDecimals] = useState(0)
  const [withdrawAmount, setWithdrawAmount] = useState(0)
  const [_BNB_Balance, setBNB_Balance] = useState(0.0)

  const [depositLoader, setDepositLoader] = useState(false)

  const [modalStatus, setModalStatus] = useState(false)
  const [withdrawModal, setWithdrawModal] = useState(false)

  const handleToggle = () => setModalStatus((prevState) => !prevState)
  const toggleWithdrawModal = () => setWithdrawModal((prevState) => !prevState)

  const [isPending, startTransition] = useTransition()

  const { connector, library, account, chainId, activate, deactivate, active, errorWeb3Modal } = useWeb3React()

  const [networklist, setNetworkList, find] = useNetworkList()
  const current_network = find(chainId)
  const switchNetwork = async (hex) => {
    await changeNetwork(hex, library)
  }
  const loadProvider = async () => {
    try {
      const web3Modal = new Web3Modal()
      const connection = await web3Modal.connect()
      const provider = new ethers.providers.Web3Provider(connection)
      return provider.getSigner()
    } catch (e) {
      console.log('loadProvider: ', e)
    }
  }

  const setTokenBalances = useCallback(async () => {
    if (library && account) {
      try {
        console.log('STEP 1')
        setLoader(true)
        //BNB Balance
        const bnb_balance = await library.getBalance(account)
        setBNB_Balance(ethers.utils.formatEther(bnb_balance))

        console.log('STEP 2')

        let signer = await loadProvider()
        let TokenContract = new ethers.Contract(USDG_addr, TokenABI, signer)
        let USDTContract = new ethers.Contract(uSDT_addr, TokenABI, signer)
        let USDTMetaData = new ethers.Contract(uSDT_addr, TokenMETADATA, signer)
        let Decimal = await USDTMetaData.decimals()
        setDecimals(Decimal)

        console.log('STEP 3')
        let _USDT_Balance = await USDTContract.balanceOf(account)
        let _USDG_Balance = await TokenContract.balanceOf(account)
        let balance = ethers.utils.formatUnits(_USDT_Balance.toString(), Decimal)

        console.log('STEP 4')

        setBalance(balance)
        setTokenBalance(ethers.utils.formatEther(_USDG_Balance))

        console.log({ _USDT_Balance, _USDG_Balance, balance })

        setLoader(false)
        console.log('library', library)
        console.log('STEP 5')
      } catch (error) {
        console.error('ERROR', error.message)
        setBalance(0)
        setLoader(false)
      }
      return () => {
        setBNB_Balance(0.0)
      }
    }
  }, [library, account, chainId])

  useEffect(() => {
    ;(async () => {
      await setTokenBalances()
    })()
  }, [account, chainId])

  const onChangeHandler = (e) => {
    setDepositValue(e.target.value)

    // startTransition(async () => {
    //   const signer = await loadProvider()
    //   const TokenContract = new ethers.Contract(USDG_addr, TokenABI, signer)
    //   const amount = ethers.utils.parseEther(e.target.value)
    //   const getPrice = await TokenContract.getPrice(amount)
    //   setAmountValue(ethers.utils.formatUnits(getPrice.toString(), '8'))
    // })
  }
  const onChangeWithdraw = (e) => {
    setWithdrawAmount(e.target.value)

    startTransition(async () => {
      const signer = await loadProvider()
      // const TokenContract = new ethers.Contract(USDG_addr, TokenABI, signer)
      // const amount = ethers.utils.parseEther(e.target.value)
      // const getPrice = await TokenContract.getPrice(amount);
      // setAmountValue(ethers.utils.formatUnits(getPrice.toString(), '8'))
    })
  }

  const deposit = async () => {
    try {
      if (depositValue <= 0) return
      setDepositLoader(true)
      toast.info('Deposit Started')
      let signer = await loadProvider()
      let TokenContract = new ethers.Contract(USDG_addr, TokenABI, signer)
      let USDTContract = new ethers.Contract(uSDT_addr, TokenABI, signer)
      let amount = ethers.utils.parseUnits(depositValue, decimals)
      let approve = await USDTContract.approve(USDG_addr, amount)
      let tran = await approve.wait()

      if (tran.confirmations > 0) {
        let _deposit = await TokenContract.mint(uSDT_addr, account, amount)
        let tx = await _deposit.wait()
      }

      // console.log(amount.toString())
      //  let getPrice = await TokenContract.getPrice(amount);
      // console.log(getPrice.toString())

      setDepositLoader(false)
      handleToggle()
      console.log('heres')
      await setTokenBalances()
      toast.success(`Successfully deposited ${depositValue} USDT`)
    } catch (e) {
      setDepositLoader(false)
      toast.error(e.message)
      console.log(e)
    }
  }

  const withdraw = async () => {
    try {
      if (withdrawAmount > 100) {
        toast.info('Not allowed to withdraw more than 100 USDT')
        return
      }
      if (withdrawAmount <= 0) return
      setDepositLoader(true)
      toast.info('Withdraw Started')
      const signer = await loadProvider()
      const USDGContract = new ethers.Contract(USDG_addr, TokenABI, signer)
      let amount = ethers.utils.parseUnits(withdrawAmount.toString(), decimals)

      const tx = await USDGContract.burn(uSDT_addr, amount)
      await tx.wait()

      toggleWithdrawModal()
      await setTokenBalances()
      toast.success(`Successfully withdrawn ${depositValue} USDG`)
      setDepositLoader(false)
    } catch (e) {
      console.log('ERROR:', e)
      toast.error(e.message)
      setDepositLoader(false)
    }
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex', backgroundColor: '#FFF' }} className="dashboard">
        <CssBaseline />
        {props.sidebar}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[0] : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          {/* <Toolbar /> */}

          {props.header}
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container sx={{ mt: 4, mb: 4 }} justifyContent="space-between" direction="row">
              <Grid item xs={12}>
                <div className="content d-flex heading-flex-column">
                  <div className="heading">
                    <h2 style={{ color: '#FFF' }}>Balance</h2>
                  </div>
                  <div className="buttons mainside custom-chain-btn-flex">
                    <button className="anchor" onClick={handleToggle}>
                      Deposit
                    </button>
                    <button className="withdraw-btn anchor" onClick={toggleWithdrawModal}>
                      WithDraw
                    </button>
                    <Dropdown className="custom-chain-dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                        USDT
                        <ArrowDropDownIcon />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">USDT</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Grid>
              {/* <Grid item container alignItems="center" className="balance-box-main">
                <Grid item my={1} minWidth={180}>
                  <h3 className="m-0" style={{ color: '#FFF' }}>
                    USDT Balance:{' '}
                  </h3>
                </Grid>
                <Grid item sx={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
                  {loader ? (
                    <Skeleton
                      variant="text"
                      style={{
                        height: '1.75em',
                        width: '20%',
                        backgroundColor: '#8364E2',
                      }}
                    />
                  ) : (
                    <h3 className="mt=1 text-white m-0 "> {parseFloat(balance).toFixed(8)} </h3>
                  )}
                </Grid>
              </Grid> */}

              <Grid item container alignItems="center" className="balance-box-main">
                <Grid item minWidth={250}>
                  <h3 style={{ color: '#FFF' }}>USDG Balance: </h3>
                </Grid>
                <Grid item sx={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
                  {loader ? (
                    <Skeleton
                      variant="text"
                      style={{
                        height: '2em',
                        width: '20%',
                        backgroundColor: '#8364E2',
                      }}
                    />
                  ) : (
                    <h3 className="mt=1 text-white"> {tokenBalance} </h3>
                  )}
                </Grid>
              </Grid>
              {/* <Grid item container alignItems="center" className="balance-box-main">
                <Grid item minWidth={180}>
                  <h3 style={{ color: '#FFF' }}>{current_network?.token} Balance: </h3>
                </Grid>
                <Grid item sx={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
                  {loader ? (
                    <Skeleton
                      variant="text"
                      style={{
                        height: '2em',
                        width: '20%',
                        backgroundColor: '#8364E2',
                      }}
                    />
                  ) : (
                    <h3 className="mt=1 text-white"> {precisionRound(_BNB_Balance, 8)} </h3>
                  )}
                </Grid>
              </Grid> */}
            </Grid>
            <Grid container spacing={3}></Grid>
          </Container>
        </Box>
      </Box>
      <CertificateModal />
      {/***********       Deposit Dialog       ************/}
      <DepositModal
        isPending={isPending}
        amountValue={amountValue}
        deposit1={deposit}
        modalStatus={modalStatus}
        depositLoader={depositLoader}
        handleToggle={handleToggle}
        depositValue={depositValue}
        onChangeHandler={onChangeHandler}
      />
      {/***********       Withdraw Dialog       ************/}
      <WithdrawModal
        status={withdrawModal}
        toggle={toggleWithdrawModal}
        withdraw={withdraw}
        amountValue={amountValue}
        withdrawAmount={withdrawAmount}
        onChangeWithdraw={onChangeWithdraw}
        isPending={isPending}
        loader={depositLoader}
      />
    </ThemeProvider>
  )
}

export default function Dashboard(props) {
  const state = useSelector((state) => state.user)
  useEffect(() => {
    if (!state.user) {
      navigate(`/signIn`)
    }
  }, [])
  return <DashboardContent header={props.header} sidebar={props.sidebar} />
}
