import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
// import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'persist-key',
  storage,
}
const middleware = [thunk]
// const store = createStore(
//   rootReducer,
//   {},
//   composeWithDevTools(applyMiddleware(...middleware))
// );

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, applyMiddleware(...middleware))
const persistor = persistStore(store)

export default store
export { persistor }
