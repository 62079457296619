const getConnectedWalletName = (library = null) => {
  if (library) {
    //Wallet Connect Condition
    console.log(library?.provider?.isCoinbaseWallet)
    if (library?.provider?.isWalletConnect) return 'WalletConnect'

    //Coin Base Condition
    if (library?.provider?.isCoinbaseWallet) return 'Coinbase'

    //MetaMask Condition
    if (library?.provider?.isMetaMask) return 'Metamask'
  }
}

export default getConnectedWalletName
