import { React, useEffect, useState, useTransition } from 'react'
import { ethers } from 'ethers'
import { toast } from 'react-toastify'
import { Dialog, DialogContent, Grid } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import Geocode from 'react-geocode'

import ABI from '../../../contract/GameRee1155.json'
import TokenABI from '../../../contract/USDG.json'

import DepositModal from './DepositModal'
import deposit from '../../../helpers/deposit'
import PreviewImage from './PreviewImage'
import apis from '../../../services'
import { loadProvider } from '../../../helpers'
import { USDG_addr, NFT_addr } from '../../../contract/addresses'
import { AssetProfile, Buy, Offer, Street } from '../../CertificatetTabs'
import mintImg from '../../../assets/images/mint.png'
import streetImg from '../../../assets/images/streetview.png'
import ApiLink from '../../pages/ApiLink'
import axios from 'axios'

export default function BuyDigitalCerfiticate(props) {
  const { status, toggleModal, data, position, refreshMapHandler, mapboxData, allNFTPics, cloudnaryData } = props
  const [buildingData, setBuildingData] = useState({})
  const [nftFiltered, setNftFiltered] = useState([])

  const { account } = useWeb3React()
  const token_Type = '57896044618658097711785492504343953927315557066662158946655541218820101242880'
  const googleMapsApiKey = 'AIzaSyA4bN_JLbgMsrsaspEm1ebHDiTNNvE7DTA'

  const [isLoading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [tab, setTab] = useState('')
  const openTab = (e) => {
    setTab(e)
    setIsOpen(true)
  }

  const closeTab = (status) => {
    setTab('')
    setIsOpen(false)
  }
  const [mapBoxUrlData, setUrlData] = useState([])
  useEffect(() => {
    async function fetchData() {
      Geocode.setApiKey(googleMapsApiKey)
      Geocode.fromLatLng(position.lat, position.lng).then((response) => {
        const shortAddress = response.results[0].formatted_address
        console.log(shortAddress)
        // const url = 'http://localhost:4000'
        axios.post(`${ApiLink}/findUniqueAddress`, { address: shortAddress }).then((res) => {
          setBuildingData(res.data[0])
          console.log(buildingData)
        })
        // fetch(`${ApiLink}/findUniqueAddress`, {
        //   method: 'POST', // or 'PUT'
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: body,
        // })
        //   .then((res) => res.json())
        //   .then((json) => {
        //     console.log(json[0])
        //     setData(json[0])
        //     // const buildings = data.buildings
        //     // if (buildings === undefined) setUrlData([])
        //     // else setUrlData([...buildings])

        //     // console.log('mapbox collection data', data.buildings)
        //   })

        const allNfts = cloudnaryData.resources.map((item) => {
          const [, , ...rest] = item.public_id.split('_')
          return {
            public_id: rest.toString(),
            url: item.url,
          }
        })

        const nftFiltered = allNfts.filter((item) => item.public_id.match(shortAddress))
        setNftFiltered(nftFiltered.map((item) => item.url))
      })
    }
    fetchData()
  }, [mapboxData])

  const b_data = {}
  if (buildingData?.['address']) {
    b_data['buildingName'] = buildingData?.['address'].split(',')[0] || ''
    b_data['streetName'] = buildingData?.['address'].split(',')[1] || ''
  } else {
    b_data['buildingName'] = data?.['building_name'] || ''
    b_data['streetName'] = data?.['street_name'] || ''
  }
  // b_data['buildingName'] = data?.['building_name'] || ''
  // b_data['buildingNumber'] = data?.['Building number'] || ''
  // b_data['name'] = data?.['owner'] || ''
  // b_data['noOfUnits'] = data?.['total_units'] || ''
  // b_data['totalAreaInSqt'] = data?.['total_gross_area_sqt'] || ''
  // b_data['city'] = data?.['city'] || ''
  // b_data['currentNFT_Price'] = data?.['current_nft_price'] || ''
  // b_data['priceInEuro'] = data?.['initial_price']
  b_data['totalAreaInSqt'] = buildingData?.['area'] || 90
  b_data['image'] = data?.['image']
  // b_data['owner'] = data?.['owner'] || ''
  // b_data['postCode'] = data?.['postal_code']
  b_data['pricePerSquare'] = data?.['price_per_square'] || ''
  // b_data['roadName'] = data?.['street_name']
  // b_data['streetName'] = data?.['street_name']
  // b_data['id'] = data?.['id'] || 0
  // b_data['_account'] = data?.['account'] || ''

  // var _account = b_data['_account']

  const [balance, setBalance] = useState(0.0)
  const [depositValue, setDepositValue] = useState(0)
  const [modalStatus, setModalStatus] = useState(false)
  const [previewImage, setPreviewImage] = useState(false)
  const [isPending, startTransition] = useTransition()
  const [amountValue, setAmountValue] = useState(0)

  const handleToggle = () =>
    setModalStatus((prevState) => {
      setTab('')
      return !prevState
    })

  const onChangeHandler = (e) => {
    setDepositValue(e.target.value)
    startTransition(async () => {
      const signer = await loadProvider()
      const TokenContract = new ethers.Contract(USDG_addr, TokenABI, signer)
      const amount = ethers.utils.parseEther(e.target.value)
      const getPrice = await TokenContract.getPrice(amount)
      setAmountValue(ethers.utils.formatUnits(getPrice.toString(), '8'))
    })
  }
  const deposit1 = () => {
    deposit(account, depositValue, handleToggle, loadProvider)
  }

  const mint = async () => {
    try {
      //PINATA LOGIC
      // console.log(data, '********************');
      const metaDataURI = await apis.pinataJSONToIPFS(data)

      setLoading(true)
      let signer = await loadProvider()
      let NFTCrowdsaleContract = new ethers.Contract(NFT_addr, ABI, signer)
      let TokenContract = new ethers.Contract(USDG_addr, TokenABI, signer)
      console.log('BALANCE', TokenContract)
      let balance = await TokenContract?.balanceOf(account)
      console.log('BALANCE', balance)

      if (balance.toString() !== '0') {
        toast.info('Minting Process Started')
        let price = await NFTCrowdsaleContract.getMintingPricePerSquare()
        let allowance = ethers.utils.formatEther(price) * b_data['totalAreaInSqt']
        let approve = await TokenContract.approve(NFT_addr, ethers.utils.parseEther(allowance.toString()))
        let tx1 = await approve.wait()
        // console.log(tx1)

        if (tx1.confirmations > 0) {
          let mint = await NFTCrowdsaleContract.mintNonFungibleWithURI(
            token_Type,
            [account],
            [],
            metaDataURI,
            b_data['totalAreaInSqt']
          )
          await mint.wait()
          const mintedID = await NFTCrowdsaleContract._lastTokenId()
          await apis.saveMapNFT({
            id: data.id.toString(),
            nft_id: mintedID.toString(),
          })
        }

        toast.success('Successfully Minted')
        toggleModal()
        refreshMapHandler()
        setLoading(false)
      } else {
        setLoading(false)
        toast.error('You Dont have USDG tokens')
      }
    } catch (e) {
      console.error('data', e)
      setLoading(false)
      toast.error('some error occurred can not mint')
      // setError('some error occurred can not mint')
    }
  }
  useEffect(() => {
    ;(async () => {
      if (account) {
        try {
          const signer = await loadProvider()
          const TokenContract = new ethers.Contract(USDG_addr, TokenABI, signer)
          // const _balance = await TokenContract.balanceOf(account) unused balance
        } catch (e) {
          console.log(e)
        }
      }
    })()
  }, [account])
  return (
    <>
      <Dialog
        open={status}
        onClose={toggleModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        hideBackdrop={true}
        transitionDuration={0.5}
        maxWidth={'md'}
        className="certificate-modal"
      >
        <DialogContent sx={{ margin: 'auto' }} className="primary-scroll blur-bg">
          {/* <BuyCertificateTab tab={tab} openTab={openTab} /> */}
          <div className="certificate-body">
            <div className="tag-box">
              <div className="title-box">
                <h1>{b_data['buildingName']}</h1>
                <h3>{b_data['streetName']}</h3>
              </div>
              <h3>THE PROPERTY IS FOR SALE</h3>
            </div>
            {mapBoxUrlData.length === 0 ? (
              nftFiltered.length === 0 ? (
                <img
                  src={b_data['image']}
                  alt="building-picture2"
                  className="building-img"
                  onClick={() => setPreviewImage(true)}
                />
              ) : (
                <img
                  src={nftFiltered[0]}
                  alt="building-picture1"
                  className="building-img"
                  onClick={() => setPreviewImage(true)}
                />
              )
            ) : (
              <img
                src={mapBoxUrlData[0].nftPic}
                alt="building-picture1"
                className="building-img"
                onClick={() => setPreviewImage(true)}
              />
            )}

            {/* <img src={b_data['image']} className="building-img" onClick={() => setPreviewImage(true)} /> */}

            <Grid container spacing={0} className="building-detail">
              <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'rgba(7, 14, 30, 0.17)' }}>
                <h1 className="title-1">OWNER OF THE PROPERTY</h1>
                <h2 className="value">SALENA ZOBIC</h2>
              </Grid>
              <Grid item xs={6} sx={{ borderRight: 1, borderColor: 'rgba(7, 14, 30, 0.17)' }}>
                <h1 className="title-2">USDG PRICE</h1>
                <h2 className="value">
                  {Math.round((b_data['pricePerSquare'] * b_data['totalAreaInSqt']) / 10) / 100} USDG{' '}
                </h2>
              </Grid>
              <Grid item xs={6}>
                <h1 className="title-2">SQFT SIZE</h1>
                <h2 className="value">{b_data['totalAreaInSqt']}</h2>
              </Grid>
            </Grid>

            <div className="button-box flex-container">
              <button className="custom-btn" onClick={() => openTab('street')} disabled={isLoading}>
                <span>
                  <img src={streetImg} width={'35px'} height={'35px'} alt=""></img>
                </span>
                STREET VIEW
              </button>
              <button className="primary-btn" onClick={mint} disabled={isLoading}>
                <span className="mx-1">
                  <img src={mintImg} width={'35px'} height={'35px'} alt=""></img>
                </span>
                {isLoading ? 'MINTING' : 'MINT NOW'}
              </button>
            </div>
          </div>

          {tab === 'buy' ? (
            <Buy setIsOpen={closeTab} isOpen={isOpen} balance={balance} handleToggle={handleToggle} />
          ) : tab === 'asset' ? (
            <AssetProfile setIsOpen={closeTab} isOpen={isOpen} b_data={b_data} handleToggle={handleToggle} />
          ) : tab === 'offer' ? (
            <Offer setIsOpen={closeTab} isOpen={isOpen} id={b_data['id']} handleToggle={handleToggle} />
          ) : (
            tab === 'street' && (
              <Street
                setIsOpen={closeTab}
                isOpen={isOpen}
                // position={position}
                mapBoxData={mapboxData}
              />
            )
          )}
        </DialogContent>
      </Dialog>
      <DepositModal
        isPending={isPending}
        amountValue={amountValue}
        deposit={deposit1}
        modalStatus={modalStatus}
        handleToggle={handleToggle}
        depositValue={depositValue}
        onChangeHandler={onChangeHandler}
      />
      {nftFiltered.length === 0 ? (
        <PreviewImage status={previewImage} toggleModal={setPreviewImage} image={b_data['image']} />
      ) : (
        <PreviewImage status={previewImage} toggleModal={setPreviewImage} image={nftFiltered[0]} />
      )}
    </>
  )
}
