import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import StreetView from '../../components/components/mapbox/streetView'

const Street = ({ isOpen, setIsOpen, position, mapBoxData }) => {
  return (
    <>
      <div className={'street-view-modal primary-scroll ' + (isOpen ? 'resell-show' : '')}>
        <div className="street-header">
          <CloseIcon onClick={() => setIsOpen(false)} />
        </div>

        <StreetView address={mapBoxData} />
      </div>
    </>
  )
}

export default Street
