import { useEffect, useRef } from 'react'

const WebLoader = () => {
  const videoRef = useRef(null)
  /*useEffect(() => {
        if(videoRef.current) {
            console.log(videoRef.current,' ***********')
            videoRef.current.play();
        }
    }, []);*/

  const attemptPlay = () => {
    videoRef &&
      videoRef.current &&
      videoRef.current.play().catch((error) => {
        console.error('Error attempting to play', error)
      })
  }

  useEffect(() => {
    attemptPlay()
  }, [])
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'black',
        width: '100vw',
        height: '100vh',
        zIndex: 999,
      }}
    >
      <video width={'100%'} height={'100%'} autoPlay muted loop>
        <source src={`videos/webloader.mp4`} type="video/mp4" style={{ objectFit: 'fill', width: '100vw' }} />
      </video>
    </div>
  )
}

export default WebLoader
