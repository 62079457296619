// import { useWeb3React } from '@web3-react/core'

const changeNetwork = async (hex, library) => {
  await library.provider.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: hex }],
  })
}

export default changeNetwork
