import CustomDialog from '../../Dialog'
import { DialogActions, Grid, InputAdornment, TextField } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import truncateEthAddress from '../../../helpers/truncateWalletAddress'
import { useCallback, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { config_addr } from '../../../contract/addresses'
import ConfigABI from '../../../contract/Config.json'
import { loadProvider } from '../../../helpers'

export default function DepositModal({
  modalStatus,
  handleToggle,
  depositValue,
  onChangeHandler,
  isPending,
  amountValue,
  deposit1,
  depositLoader,
}) {
  const { account } = useWeb3React()
  const [fee, setFee] = useState(0.0)

  const getMintingFee = useCallback(async () => {
    try {
      const signer = await loadProvider()
      const ConfigContract = new ethers.Contract(config_addr, ConfigABI, signer)
      const feeInBigNumber = await ConfigContract.minting_fee()
      const fee = Number(feeInBigNumber.toString())
      const denominatorBigNumber = await ConfigContract.feeDenominator()
      const denominator = Number(denominatorBigNumber.toString())

      return (fee / denominator) * 100
    } catch (e) {
      console.error('ERROR: ->', e)
    }
  }, [depositValue])

  useEffect(() => {
    ;(async () => {
      const _fee = await getMintingFee()
      setFee(_fee)
    })()
  }, [])

  return (
    <CustomDialog
      className="deposit-modal"
      status={modalStatus}
      toggleModal={handleToggle}
      title="Deposit USDT to USDG"
    >
      <Grid item container my={2}>
        <TextField
          placeholder="Deposit Amount"
          value={depositValue === 0 ? '' : depositValue}
          label="Deposit Amount"
          onChange={(e) => onChangeHandler(e)}
          variant="outlined"
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: <InputAdornment position="end">USDT</InputAdornment>,
          }}
        />
      </Grid>
      <ul className="deposit-ul-detail">
        <li>
          <span> Deposit to </span>
          <p> {truncateEthAddress(account)} </p>
        </li>
        <li>
          <span> Fee </span>
          <p> {fee} </p>
        </li>
        <li>
          <span>
            Approx <b>USDG</b>
          </span>
          <p> {depositValue - (depositValue * fee) / 100} </p>
        </li>
      </ul>
      <DialogActions className="flex-start p-0 mt-4">
        <button className="custom-btn primary-btn " onClick={deposit1} disabled={depositLoader}>
          {depositLoader ? 'In-Process' : 'Confirm'}
        </button>
        <button className="custom-btn danger-btn" onClick={handleToggle}>
          Cancel
        </button>
      </DialogActions>
    </CustomDialog>
  )
}
