import Grid from '@mui/material/Grid'
import { DialogActions, InputAdornment, TextField } from '@mui/material'
import CustomDialog from '../../Dialog'
import { useWeb3React } from '@web3-react/core'
import { React, useState } from 'react'
import truncateEthAddress from '../../../helpers/truncateWalletAddress'

const WithdrawModal = ({
  status,
  toggle,
  withdrawAmount,
  onChangeWithdraw,
  // isPending,
  // amountValue,
  withdraw,
  loader,
}) => {
  const { account } = useWeb3React()
  const [fee, setFee] = useState(0.0)

  // const getMintingFee = useCallback(async () => {
  //   try {
  //     const signer = await loadProvider()
  //     const ConfigContract = new ethers.Contract(config_addr, ConfigABI, signer)
  //     const feeInBigNumber = await ConfigContract.burning_fee()
  //     const denominatorBigNumber = await ConfigContract.feeDenominator()
  //     const denominator = Number(denominatorBigNumber.toString())
  //     const fee = Number(feeInBigNumber.toString())

  //     return (fee / denominator) * 100
  //   } catch (e) {
  //     console.error('ERROR: ->', e)
  //   }
  // }, [withdrawAmount])

  // useEffect(() => {
  //   ;(async () => {
  //     const _fee = await getMintingFee()
  //     setFee(_fee)
  //   })()
  // }, [])

  return (
    <CustomDialog className="deposit-modal" status={status} toggleModal={toggle} title="Withdraw USDG to USDT">
      <Grid item container my={2}>
        <TextField
          placeholder="Withdraw Amount"
          value={withdrawAmount === 0 ? '' : withdrawAmount}
          label="Withdraw Amount"
          onChange={(e) => onChangeWithdraw(e)}
          variant="outlined"
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: <InputAdornment position="end">USDT</InputAdornment>,
          }}
        />
      </Grid>
      <ul className="deposit-ul-detail">
        <li>
          <span>Withdraw to</span>
          <p> {truncateEthAddress(account)} </p>
        </li>
        <li>
          <span> Fee </span>
          <p> {fee} </p>
        </li>
        <li>
          <span>
            Approx <b>USDT</b>
          </span>
          <p> {withdrawAmount - (withdrawAmount * fee) / 100} </p>
        </li>
      </ul>
      <DialogActions className="flex-start p-0 mt-4">
        <button className="custom-btn primary-btn " onClick={withdraw} disabled={loader}>
          {loader ? 'In-Process' : 'Confirm'}
        </button>
        <button className="custom-btn danger-btn" onClick={toggle}>
          Cancel
        </button>
      </DialogActions>
    </CustomDialog>
  )
}

export default WithdrawModal
