export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SAVE_USER_DATA = 'SAVE_USER_DATA'
export const DELETE_USER_DATA = 'DELETE_USER_DATA'

export const USER_PERSONAL_DATA = 'USER_PERSONAL_DATA'
export const ADD_WALLET = 'ADD_WALLET'
export const REMOVE_WALLET = 'REMOVE_WALLET'

export const SAVE_TIME = 'SAVE_TIME'
export const DELETE_TIME = 'DELETE_TIME'

export const SAVE_ETH = 'SAVE_ETH'
export const DELETE_ETH = 'DELETE_ETH'
