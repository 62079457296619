import { useState } from 'react'
import { Dialog, DialogContent, Grid } from '@mui/material'
import profile from '../../../assets/mapUserProfile/Ellipse.png'
const FigmaProfileModal = ({ status, toggleModalProfile }) => {
  const [screen, setScreen] = useState('DETAILS') //ASSETS
  const data = [
    {
      name: '42 Oxford Street London',
      country: 'UK',
      city: 'London',
      price: '20,000 USDG',
    },
    {
      name: '42 Oxford Street London',
      country: 'UK',
      city: 'London',
      price: '20,000 USDG',
    },
  ]

  return (
    <div>
      <Dialog
        open={status}
        onClose={toggleModalProfile}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        hideBackdrop={true}
        transitionDuration={0.5}
        maxWidth={'md'}
        className="certificate-modal"
      >
        <DialogContent className="primary-scroll blur-bg">
          <div className="text-center mobile_view_profile ">
            <div className="profile-tag-box ">
              <div
                className="profile-title-box shadow"
                style={{
                  boxShadow: '0 0.5rem 1rem !important',
                }}
              >
                <h1>{'PROFILE'}</h1>
              </div>
            </div>

            <div className="text-center  mt-3">
              <span
                className="p-2 px-4"
                style={{ backgroundColor: 'white', borderRadius: '20px', fontFamily: 'TT Trailers', fontSize: '2rem' }}
              >
                <span
                  onClick={() => {
                    setScreen('DETAILS')
                  }}
                  style={{ color: screen === 'DETAILS' ? '#8264E2' : '#A4A8B2' }}
                >
                  DETAILS
                </span>{' '}
                &nbsp; | &nbsp;
                <span
                  onClick={() => {
                    setScreen('ASSETS')
                  }}
                  style={{ color: screen === 'ASSETS' ? '#8264E2' : '#A4A8B2' }}
                >
                  ASSETS
                </span>
              </span>
            </div>
            {screen === 'DETAILS' && (
              <div className="display">
                <div className="text-center mt-2">
                  <img src={profile} style={{ width: '110px' }} alt="userProfile" />
                </div>
                <div style={{ color: '#070E1E', fontFamily: 'TT Trailers', fontWeight: '700', fontSize: '2rem' }}>
                  KELVIN NOMAD
                </div>

                <div className="text-center mt-2">
                  <div className="row ">
                    <div className="col-10 offset-1  ">
                      <div
                        className="row profile-detail"
                        style={{ border: '2px solid black', borderRadius: '23px', fontFamily: 'TT Trailers' }}
                      >
                        <div className="col-12 item-profile twele-col">
                          <h1 className="title-1">TOTAL USDT EARNING</h1>
                          <h2 className="value">24,000</h2>
                        </div>
                        <div className="col-6 item-profile six-col">
                          <h1 className="title-2">Properties</h1>
                          <h2 className="value">12</h2>
                        </div>
                        <div className="col-6 item-profile">
                          <h1 className="title-2">Collections</h1>
                          <h2 className="value"> 02</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row mt-3 pb-3 shadow profile-card"
                  style={{
                    border: '2px solid black',
                    backgroundColor: '#8264E2',
                    color: 'white',
                    borderRadius: '23px',
                  }}
                >
                  <div className="col-12" style={{ fontSize: '3rem', fontFamily: 'TT Trailers' }}>
                    24,054
                  </div>
                  <div className="col-12">My USDG Balance</div>
                  <div className="col-12 mt-2">
                    <span
                      style={{
                        backgroundColor: 'white',
                        color: '#8264E2',
                        padding: '0.5rem 2rem',
                        borderRadius: '20px',
                        fontSize: '1.5rem',
                        fontFamily: 'TT Trailers',
                      }}
                    >
                      Add Funds
                    </span>
                  </div>
                </div>
              </div>
            )}
            {screen === 'ASSETS' && (
              <div>
                <div className="container mt-3">
                  <div
                    className="custom-container-profile "
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                      borderRadius: '40px',
                      border: '2px solid black',
                      paddingBottom: '60px',
                    }}
                  >
                    <h3 className="text-center" style={{ fontFamily: 'TT Trailers', fontSize: '40px' }}>
                      {' '}
                      Properties
                    </h3>
                    <div className="list-of-properties" style={{ textAlign: 'initial' }}>
                      {data.map((item, i) => (
                        <div class="d-flex bd-highlight" key={i}>
                          <div
                            class="px-1 flex-shrink-1 bd-highlight"
                            style={{ backgroundColor: 'purple', color: 'white' }}
                          >
                            {''}
                          </div>

                          <div class="p-2 w-100 bd-highlight" style={{ border: '1px solid silver' }}>
                            <div
                              className="px-2 w-100 bd-highlight"
                              style={{ fontWeight: 'bold', fontFamily: 'TT Trailers', fontSize: '30px' }}
                            >
                              {item.name}
                            </div>
                            <div className="px-2 w-100 bd-highlight">{item.name}</div>
                          </div>

                          <div
                            class="p-2 flex-shrink-1 bd-highlight"
                            style={{ backgroundColor: 'black', color: 'white' }}
                          >
                            <div
                              style={{
                                fontFamily: 'TT Trailers',
                                fontSize: '25px',
                              }}
                            >
                              {item.price.split(' ')[0]}
                            </div>
                            <div>{item.price.split(' ')[1]}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default FigmaProfileModal
