import { ethers } from 'ethers'
import { USDG_addr, uSDT_addr } from '../contract/addresses'
import TokenABI from '../contract/USDG.json'

const deposit = async (account, depositValue, decimals, handleToggle, loadProvider, getBalances = null) => {
  try {
    let signer = await loadProvider()
    let TokenContract = new ethers.Contract(USDG_addr, TokenABI, signer)
    let USDTContract = new ethers.Contract(uSDT_addr, TokenABI, signer)
    let amount = ethers.utils.parseUnits(depositValue, decimals)
    let approve = await USDTContract.approve(USDG_addr, amount)
    let tran = await approve.wait()

    if (tran.confirmations > 0) {
      let _deposit = await TokenContract.mint(uSDT_addr, account, amount)
      _deposit.wait()
    }

    // console.log(amount.toString())
    //  let getPrice = await TokenContract.getPrice(amount);
    // console.log(getPrice.toString())

    handleToggle()

    if (getBalances) await getBalances()
  } catch (error) {
    // console.log(error)
  }
}

export default deposit
