import * as React from 'react'
// import Header from "../../menu/header.js";
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

const Terms = () => {
  return (
    <>
      <Container component="main">
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 3,
            padding: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 5,
            }}
          >
            <h2>Terms and Conditions</h2>
          </Box>

          <p>
            These Terms of Service ("Terms") govern your use of GameRee ("we," "us," "our," or "Company") services,
            including but not limited to our website, mobile or online applications, and other digital goods that
            connect to or reference these Terms (collectively, the "Services").These Terms form a legally enforceable
            contract between you (or the business you represent) and Company. "You" and "your" refer to you, a user of
            the Services, under these Terms. You or anyone else who accesses, browses, or utilizes the Services in any
            manner is referred to as a "user."
          </p>

          <p>
            GameRee is only available to users who are at least 18 years old. You represent and warrant that you are of
            legal age to make a binding contract with TSB and that you fulfil the aforementioned eligibility conditions
            by accessing or using GameRee. You must not access or use GameRee if you do not satisfy all of these
            prerequisites
          </p>
          <p>
            Please carefully read these Terms, as they may have changed. Though the Terms in place at the moment govern
            your access and use of the Services, please be aware that we reserve the right to alter and change these
            Terms at any time. If we make significant changes to these Terms, we will send you an email or post a notice
            on our website prior to the changes taking effect. These Terms are intended to guarantee that you will only
            use the Services in the manner that we intend.
          </p>

          <p>
            You indicate that you have read, understood, and accept these Terms, as well as any additional documents or
            policies referenced to in or incorporated into these Terms, whether you are a visitor or a registered user,
            by accessing or using the Services. You understand and agree that your continuing access or use of the
            Services indicates your acceptance of the revised Terms if these Terms have significantly changed since you
            last accessed or used the Services.
          </p>

          <p>
            You represent and warrant that you are at least eighteen (18) years old and have the legal right, authority,
            and capacity to enter into these Terms, either on your own behalf or on behalf of the entity you represent;
            and You consent to receive communications from us electronically, and you agree that such electronic
            communications, notices, and postings satisfy any legal requirements that such communications be in writing.
          </p>
          <br />

          <h5>The first step is to get access to the services.</h5>
          <p>
            We allow you to access and use the Services subject to the restrictions set out in these Terms and our Game
            Rules, which are expressly incorporated by reference. The accuracy, timeliness, and completeness of the
            information you supply is a requirement of your use of the Services. You use the Services at your own risk,
            which includes the risk of being exposed to offensive, indecent, incorrect, objectionable, or otherwise
            inappropriate content.{' '}
          </p>
          <p>
            You are responsible for making all required arrangements to get access to the Services. We reserve the right
            to cancel your account, suspend your ability to access specific parts of the Services, and/or prohibit you
            from using the Services altogether for any reason, with or without warning or responsibility.
          </p>
          <h5> Account Creation and Account Protection.</h5>
          <p>
            Certain features or functionalities of the Services, as well as any or all of the Services, may require you
            to create an account with us. We may require you to supply some registration data or other personal
            information when you do so. Our Privacy Policy applies to all of this information. You thus permit Company
            to establish a GameRee account in your name
          </p>
          <p>
            You are responsible for any activity that occurs in connection with your account once you have created one.
            You will keep your account access details private and will not share them with anyone else. You undertake to
            contact us promptly if you have reason to suspect that your account credentials have been hacked, if your
            account or password has been misused, or if there has been any other security breach.
          </p>
          <p>
            When accessing your profile from a public or shared computer, or when using your account in a public area,
            such as a park, cafe, or public library, we urge that you exercise extra caution so that others cannot
            observe or record your access credentials or other personal information.
          </p>
          <p>
            You may not establish an account in someone else's name, create or use an account for someone other than
            yourself, allows anyone else to use your account, or give personal information for purposes other than your
            own account registration. You are not permitted to use multiple accounts for the same Service. We reserve
            the right to disable or cancel any user account at any time and for any reason or no reason in order to
            safeguard and properly manage the Services and our community of users.
          </p>
          <h5>Uses that are not permitted.</h5>
          <p>
            You may only use the Services in compliance with these Terms and for legitimate reasons. We are not
            obligated to enforce the Terms on your behalf if another user violates them. If you suspect another user has
            broken the Terms or otherwise engaged in forbidden or unlawful behavior, we urge you to contact us.
          </p>
          <ul>
            <li>You undertake not to aid, encourage, or enable anyone to use the Services, and you will not do so</li>
            <li>Except as specifically allowed by these Terms, for any commercial purpose.</li>
            <li>
              To break any legal law or regulation at the national, regional, federal, state, municipal, or
              international levels.
            </li>
            <li>To make, send, receive, display, transmit, upload, download, utilize, or reuse any material that:</li>
            <li>
              Defamatory, obscene, indecent, abusive, insulting, harassing, violent, hateful, inflammatory, or otherwise
              undesirable content;
            </li>
            <li>
              Infringe on another person's patent, trademark, trade secret, copyright, or other intellectual property
              rights;
            </li>
            <li>Violate these Terms, as well as any other rules or policies we've set, such as our Games Rules.</li>
            <li>Any part of the Services can be reverse engineered.</li>
            <li>
              Except as specifically allowed by Company, you may not modify, adapt, appropriate, reproduce, distribute,
              create derivative works or adaptations of, publicly display, sell, trade, or otherwise exploit the
              Services.
            </li>

            <li>
              Remove or change any copyright, trademark, or other property rights notice that exists on any part of the
              Services, as well as any items printed or copied from the Services.
            </li>
            <li>Information about other users can be recorded, processed, harvested, collected, or mined.</li>
            <li>
              Any component of the Services may be accessed, retrieved, or indexed for the purpose of creating or
              populating a searchable database.
            </li>
            <li>
              To access the Services for any reason, use any robot, spider, or other automated device, method, or means.
            </li>
            <li>
              Use the Services to spread computer viruses, worms, bugs, Trojan horses, or other harmful software
              (collectively, "Viruses").
            </li>
            <li>
              Use the Services to break into any computer network's security, crack passwords, or decrypt security
              encryption codes.
            </li>

            <li>
              Any security-related features or other restrictions of the Services may be removed, circumvented,
              disabled, damaged, or otherwise interfered with. Attempt to gain unauthorized access to, interfere with,
              damage, or
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage, or disrupt any element of the Services,
              the server on which the Services are housed, or any other server, computer, or database connected to the
              Services in any way.
            </li>
            <li>
              Trespass or attempt to obtain access to any real-world property or area where you do not have the right or
              authorization to be.
            </li>
            <li>
              Engage in any conduct that has the potential to cause harm, death, property damage, annoyance, or
              liability.
            </li>
            <li>
              Attempt to interfere with or change the proper functioning or regular behavior of the Services in any
              other way, including, but not limited to:{' '}
            </li>
            <li>
              Sharing accounts with other users; attempting to modify or misrepresent a device's location in any way
              (for example, by GPS spoofing); and any other sort of cheating
            </li>
            <li>
              If you engage in any of the prohibited activities listed above, we may suspend or terminate your user
              account and/or delete, revoke, or modify your access to the Services in our sole and absolute discretion,
              without notice to you and without limiting any of our other rights or remedies at law or in equity.
            </li>
          </ul>
          <p>
            Intellectual Property Ownership. We own the Services, as well as all of our trademarks, logos, branding, and
            other content created in connection with the Services ("Company Content"), as well as all proprietary rights
            of any kind and nature associated with such Company Content and the Services, whether registered or
            unregistered, throughout the world (collectively, "Company IP"). We do not grant you any explicit or implied
            rights, and we reserve all rights in and to the Company IP, except as expressly and unambiguously specified
            below.
          </p>
          <p>
            We are not responsible or accountable to any third party for the content or accuracy of any materials posted
            on the Services by you or any other user. You understand that when you use the Services, you will be exposed
            to text, images, photos, audio, video, location data, and other types of data or communication
            (collectively, "Content") from a variety of sources, and that Company is not responsible for the accuracy,
            usefulness, safety, or intellectual property rights of or relating to such Content. We fully disclaim any
            and all duty in connection with any Content made accessible through the Services by any user or other
            licensee, as well as any opinion, suggestion, or advice stated therein.
          </p>
          <p>Take ownership of your content.</p>
          <p>
            You understand and acknowledge that you, not Company, are solely responsible for the Content you submit or
            transmit to, though, or in connection with the Services that you publicly display or display in your account
            profile (collectively, "Your Content"), and that you, not Company, bear all risks associated with Your
            Content, including anyone's reliance on its quality, accuracy, reliability, or appropriateness, or any
            disclosure by you of information in Your Content that makes you liable.
          </p>
          <p>
            You represent and warrant that you own or have the relevant rights, consents, and permissions to use and
            authorize the use of Your Content in the manner set out above. You may not indicate that Company has
            sponsored or authorized Your Content in any way. You guarantee that Your Content does not infringe on these
            Terms.
          </p>
          <p>
            You understand that when you use the Services, you will be exposed to text, images, photos, audio, video,
            location data, and other types of data or communication (collectively, "Content") from a variety of sources,
            and that Company is not responsible for the accuracy, usefulness, safety, or intellectual property rights of
            or relating to such Content.
          </p>
          <p>
            We fully disclaim any and all duty in connection with any Content made accessible through the Services by
            any user or other licensee, as well as any opinion, suggestion, or advice stated therein.
          </p>
          <p>Infringement of copyright and the Digital Millennium Copyright Act</p>
          <p>
            We respect other people's intellectual property rights and expect our users to do the same. In appropriate
            circumstances and in our sole discretion, we may terminate the rights of any user to use the Services (or
            any portion thereof) who infringes on the intellectual property rights of others, in accordance with the
            Digital Millennium Copyright Act (DMCA) and other relevant law.
          </p>
          <p>
            Please give the following information to the "Copyright Agent" if you feel your work has been duplicated in
            a way that constitutes copyright infringement, or if you are aware of someone infringing on your rights:
          </p>
          <p>
            A signature of the person authorized to act on behalf of the owner of the copyright interest, either
            electronically or physically.
          </p>
          <p>A description of the copyrighted work you allege has been tampered with.</p>
          <p>A description of the location on the Services of the allegedly infringing material.</p>
          <p>Your name, address, and phone number, as well as your email address.</p>
          <p>
            A declaration stating you believe the disputed use is not permitted by the copyright owner, its agent, or
            the law.
          </p>
          <p>
            A statement signed under penalty of perjury that the information in your notification is correct and that
            you are the copyright owner or authorized to act on the copyright owner's behalf.
          </p>

          <h5>You are granting us Right to use your Content </h5>
          <p>
            You hereby offer us irrevocable worldwide, permanent, non-exclusive, royalty-free, assignable, sub
            licensable and transferable rights to use Your Content for any purpose. You agree that we may use Your
            Content in a variety of ways as a result of this grant, including displaying it publicly, reformatting it,
            including it into advertising and other works, producing derivative works from it, marketing it, and
            distributing it.
          </p>
          <p>
            Please keep in mind that you also provide users of the Services an irrevocable right to view Your Content as
            part of their usage of the Services. Finally, you irrevocably renounce, and cause to be waived, all claims
            and assertions of moral right or attribution with regard to Your Content against Company and its users. We
            mean "use," "create," "have made," "sell," "offer for sale," "import," "practice," "copy," "publicly perform
            and exhibit," "reproduce, perform, disseminate," "modify," "remove," "analyze," "commercialize," and
            "prepare derivative works" when we say "use."
          </p>
          <p>
            Except as otherwise expressly stated in these Terms or our other policies, we are not and will not be
            obligated to (1) keep Your Content confidential; (2) pay you any compensation for Your Content; (3) credit
            or acknowledge you for Your Content; (4) respond to Your Content; or (5) exercise any of the rights granted
            herein with respect to Your Content.
          </p>

          <h5>Acquisitions </h5>
          <p>
            GRE You can buy GRE in-game currency with real-world money ("Cash Purchases"), which can subsequently be
            used to buy virtual properties and other digital items through the Services ("Virtual Purchases"). All
            information you submit in connection with a Cash Purchase is truthful and correct, and you represent and
            warrant this. Company is not liable for any Cash Purchases for which you submitted misleading or incomplete
            information, as decided by Company.
          </p>
          <p>
            Purchases made virtually. If you opt to use block chain technology to conduct and manage Virtual Purchases
            in the Services, such Virtual Purchases will be done and managed using blockchain technology. When you use
            GMRE to conduct a transaction, your GameRee account becomes publicly available. We shall have no knowledge
            of or control over Virtual Purchases made between users, and we will be unable to reverse any such
            transactions. As a result, we will not be liable to you or any third parties for any claims or damages
            arising out of Virtual Purchases.
          </p>

          <h5>Commission of GameRee </h5>
          <p>
            The standard commission is 10% of the value of the appropriate Virtual Purchase, which will be split equally
            between the seller and the purchaser, who will each pay 5%. It's possible that the standard commission will
            change over time.
          </p>

          <h5>Connectivity with other websites.</h5>
          <p>
            The standard commission is 10% of the value of the appropriate Virtual Purchase, which will be split equally
            between the seller and the purchaser, who will each pay 5%. It's possible that the standard commission will
            change over time.
          </p>
          <p>
            If the Services contain connections to other websites or resources, these links are provided only for the
            convenience of our users. We have no control over the contents of those websites or resources, and as a
            result, we cannot be held liable for them or any loss or damage incurred as a result of your use of them. If
            you choose to visit any of the third-party websites connected to the Services, you do so solely at your own
            risk and are bound by the terms and conditions of use for such websites.
          </p>

          <h5>24/7 Provision of Services not Guaranteed</h5>
          <p>
            Although we strive to keep the Services available at all times in the future, there may be situations when
            we must temporarily or permanently shut the Site. Without prior warning or liability, the Services may be
            changed, updated, stopped, suspended, or cancelled at any time. Keep in mind that the Company will not be
            liable if the Services, in whole or in part, are unavailable at any time or for any amount of time. We may
            also restrict access to any portions of the Services, or the entire Services, to users, including registered
            users, from time to time. We cannot and do not make any warranties or representations about the devices you
            use to access or use the Services, including device compatibility.
          </p>

          <h5>Acknowledgement. </h5>
          <p>The following is something you accept and agree to:</p>
          <p>
            The Services include location-based capabilities, which means you will be able to use your mobile phone to
            access the Services in real-world places. You use the Services at your own risk, and you are responsible for
            maintaining any appropriate health, liability, hazmat, personal injury, medical, life, and other insurance
            policies for any injuries you may sustain while using the Services.
          </p>
          <p>
            You hereby release Company (and our officers, directors, agents, subsidiaries, joint ventures, and
            employees) from any and all claims, demands, and damages (actual and consequential) of every kind and
            nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any
            way connected with such disputes.
          </p>
          <h6>
            “The virtual properties in the services are available for purchase by the users of the services on a first
            come, first served basis. Company no longer has any virtual ownership or control over a virtual property
            purchased through the services after it has been purchased by a user.”{' '}
          </h6>
          <p>
            A monetary policy governs the Services in order to maintain economic stability. You may not influence the
            Services economy in such a way that it collapses or fluctuates in an unfavorable way. If you do so, the
            Company reserves the right to refuse you access to all or part of the Services.
          </p>
          <p>
            Except for Company's income taxes, you will be solely responsible for paying any tax duties, levies, or
            assessments associated with your use of the Services (including, without limitation, any Taxes that may
            become payable as a result of your "ownership," transfer, or development of any virtual property in the
            Services) whenever claimed or imposed by any governmental authority (collectively "Taxes").
          </p>

          <h5>Liability Limitation.</h5>
          <p>
            to the fullest extent permitted by law, company shall not be liable for any indirect, incidental, special,
            exemplary, or consequential damages arising out of or in connection with the services, whether or not
            company has been advised of the possibility of such damages, or from any communications, interactions,
          </p>

          <h5>Indemnification</h5>
          <p>
            You agree to hold the Company, its affiliates, and licensors, as well as their respective officers,
            directors, employees, contractors, agents, licensors, and suppliers, harmless from and against any claims,
            liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys'
            fees) resulting from your violation of these Terms or use of the Services.
          </p>

          <h5>Law that governs.</h5>
          <p>
            The laws of the United Kingdom shall govern this Agreement, without regard to its conflict of law’s
            provisions. You accept to subject to the federal and London United Kingdom's personal jurisdictions.
          </p>

          <h5>Terms and Conditions.</h5>
          <p>
            These Terms are the only and final agreement between you and Company about the Services, and they replace
            any and all prior and contemporaneous understandings, agreements, representations, and warranties, whether
            written and oral, regarding the Services. Company's waiver of any term or condition of these Terms will not
            be construed as a further or continuing waiver of that term or condition or any other term or condition, and
            Company's failure to assert any right or provision under these Terms will not be construed as a waiver of
            that right or provision. If any provision of these Terms is found to be invalid, illegal, or unenforceable
            by a court of competent jurisdiction for any reason, that provision will be modified to reflect the parties'
            intention or eliminated to the extent necessary so that the remaining provisions of the Terms will remain in
            full force and effect. You may not assign, transfer, or sublicense the Terms or any rights or obligations
            hereunder without Company's prior written agreement, but we may assign or transfer the Terms or any rights
            or obligations hereunder without limitation. Any attempted assignment by you will be invalid due to a
            violation of these Terms. The section headings in the Terms are just for reference and have no legal or
            contractual significance.
          </p>
          <h5>Contact</h5>
          <p>
            All feedback, comments, technical support requests, and other communications about the Services should be
            sent to contact@Gameree.net.
          </p>
        </Box>
      </Container>
    </>
  )
}

export default Terms
