import * as React from 'react'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import useOnclickOutside from 'react-cool-onclickoutside'
import MuiDrawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '../../../assets/images/dashboard-icon.png'
import DashboardLogo from '../../../assets/images/logo-dashboard.png'
import nft_sell from '../../../assets/images/nft-sell.png'
import collection from '../../../assets/images/collection.png'
import sidebar_profile from '../../../assets/images/sidebar-profile.png'
import activity from '../../../assets/images/activity.png'
import blockuser from '../../../assets/images/block-user.png'
import clients from '../../../assets/images/clients.png'
import location from '../../../assets/images/location.png'
import transaction from '../../../assets/images/transaction.png'
import { navigate, useNavigate } from '@reach/router'

const drawerWidth = 250
const auth = localStorage.getItem('user')
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#100E22',
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))
const MainListItems = (props) => {
  const nav = useNavigate()
  return (
    <React.Fragment>
      {
        auth === 'admin' ? (
          // admin links
          <>
            <ListItemButton
              className="justify-content-center px-5 mt-2 list-main active-list-main"
              sx={{
                border: '1px solid #f1f1f1',
                backgroundColor: '#F1F1F1',
                boxShadow: '0px 2px 5px 2px rgb(116 113 113 / 27%);',
              }}
              onClick={(e) => nav('/admin/dashboard')}
            >
              <ListItemIcon>
                <img src={DashboardIcon} alt="" />
              </ListItemIcon>
              <ListItemText className="text-grey" primary="Dashboard" />
            </ListItemButton>

            <ListItemButton
              className="justify-content-center px-5 mt-2 list-main"
              sx={{
                border: '1px solid #f1f1f1',
              }}
              onClick={(e) => nav('/admin/block-user')}
            >
              <ListItemIcon>
                <img src={blockuser} alt="" />
              </ListItemIcon>
              <ListItemText className="text-grey" primary="Block" />
            </ListItemButton>
            <ListItemButton
              className="justify-content-center px-5 mt-2 list-main"
              sx={{
                border: '1px solid #f1f1f1',
              }}
              onClick={(e) => nav('/admin/clients')}
            >
              <ListItemIcon>
                <img src={clients} alt="" />
              </ListItemIcon>
              <ListItemText className="text-grey" primary="Managing Clients" />
            </ListItemButton>
            <ListItemButton
              className="justify-content-center px-5 mt-2 list-main"
              sx={{
                border: '1px solid #f1f1f1',
              }}
              onClick={(e) => nav('/admin/user-tracking')}
            >
              <ListItemIcon>
                <img src={location} alt="" />
              </ListItemIcon>
              <ListItemText className="text-grey" primary="User Tracking" />
            </ListItemButton>
            <ListItemButton
              className="justify-content-center px-5 mt-2 list-main"
              sx={{
                border: '1px solid #f1f1f1',
              }}
              onClick={(e) => nav('/admin/transaction')}
            >
              <ListItemIcon>
                <img src={transaction} alt="" />
              </ListItemIcon>
              <ListItemText className="text-grey" primary="Financial Transaction" />
            </ListItemButton>
            <ListItemButton
              className="justify-content-center px-5 mt-2 list-main"
              sx={{
                // border: "1px solid #f1f1f1",
                backgroundColor: '#8364e2',
                margin: '1em',
                borderRadius: '6px',
                color: '#FFF',
                '&:hover': {
                  backgroundColor: 'rgba(131,100,226,0.70)',
                },
              }}
              onClick={() => navigate('/landmap')}
            >
              <ListItemText className="text-white text-center" primary="Mapbox" />
            </ListItemButton>
          </>
        ) : (
          // end admin links
          // user links
          <>
            <ListItemButton
              className="justify-content-center px-5 mt-2 list-main active-list-main"
              sx={{
                border: '1px solid #f1f1f1',
                backgroundColor: '#F1F1F1',
                boxShadow: '0px 2px 5px 2px rgb(116 113 113 / 27%);',
              }}
              onClick={(e) => nav('/dashboard')}
            >
              <ListItemIcon>
                <img src={DashboardIcon} alt="" />
              </ListItemIcon>
              <ListItemText className="text-grey" primary="Dashboard" />
            </ListItemButton>
            <ListItemButton
              className="justify-content-center px-5 mt-2 list-main"
              sx={{
                border: '1px solid #f1f1f1',
              }}
              onClick={(e) => nav('/nft-sell')}
            >
              <ListItemIcon>
                <img src={nft_sell} alt="" />
              </ListItemIcon>
              <ListItemText className="text-grey" primary="NFTs Sell" />
            </ListItemButton>
            <ListItemButton
              className="justify-content-center px-5 mt-2 list-main"
              sx={{
                border: '1px solid #f1f1f1',
              }}
              onClick={(e) => nav('/collection')}
            >
              <ListItemIcon>
                <img src={collection} alt="" />
              </ListItemIcon>
              <ListItemText className="text-grey" primary="Collection" />
            </ListItemButton>
            <ListItemButton
              className="justify-content-center px-5 mt-2 list-main"
              sx={{
                border: '1px solid #f1f1f1',
              }}
            >
              <ListItemIcon>
                <img src={sidebar_profile} alt="" />
              </ListItemIcon>
              <ListItemText className="text-grey" primary="Profile" />
            </ListItemButton>
            <ListItemButton
              className="justify-content-center px-5 mt-2 list-main"
              sx={{
                border: '1px solid #f1f1f1',
              }}
            >
              <ListItemIcon>
                <img src={activity} alt="" />
              </ListItemIcon>
              <ListItemText className="text-grey" primary="Account Activity" />
            </ListItemButton>
            <ListItemButton
              className="justify-content-center px-5 mt-2 list-main"
              sx={{
                // border: "1px solid #f1f1f1",
                backgroundColor: '#8364e2',
                margin: '1em',
                borderRadius: '6px',
                color: '#FFF',
                '&:hover': {
                  backgroundColor: 'rgba(131,100,226,0.70)',
                },
              }}
              onClick={() => navigate('/landmap')}
            >
              <ListItemText className="text-white text-center" primary="Mapbox" />
            </ListItemButton>
          </>
        )
        // end user links
      }
    </React.Fragment>
  )
}

function DashboardSidebar() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(true)
  const [TotalUsers, setTotalUsers] = useState('loading ...')
  const [openMenu2, setOpenMenu2] = useState(false)

  const toggleDrawer = () => {
    setOpen(!open)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu2)
  }
  const closeMenu2 = () => {
    setOpenMenu2(false)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const ref2 = useOnclickOutside(() => {
    closeMenu2()
  })

  function closeSidebar() {
    let element = document.getElementsByClassName('MuiDrawer-paper')[0]
    element.style.width = '0px'
  }

  const popOpen = Boolean(anchorEl)
  const id = popOpen ? 'simple-popover' : undefined
  return (
    <>
      <Drawer variant="permanent" open={open} className="custom-sidebar">
        <div className="logo d-flex sidebar-logo-div">
          <a href="/">
            {' '}
            <img src={DashboardLogo} alt="logo" />{' '}
          </a>
          <i className="fa-solid fa-xmark close-sidebar" onClick={closeSidebar}></i>
        </div>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          {/* <IconButton onClick={toggleDrawer} className="chevron-icon">
        <ChevronLeftIcon />
      </IconButton> */}
        </Toolbar>
        <Divider />
        <List component="nav">
          <MainListItems />
          {/* <Divider sx={{ my: 1 }} /> */}
          {/* {secondaryListItems} */}
        </List>
      </Drawer>
    </>
  )
}

export default DashboardSidebar
