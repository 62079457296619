const Cloudnary = {
  message: 'success',
  resources: [
    {
      asset_id: 'f6a3db8082b8625229a51e6bd3aafcc5',
      public_id: 'NFT- Buildings/NFT_57_297_Oxford_Street_London_lyysdi',
      format: 'jpg',
      version: 1664176883,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:23Z',
      bytes: 905962,
      width: 996,
      height: 1044,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176883/NFT-%20Buildings/NFT_57_297_Oxford_Street_London_lyysdi.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176883/NFT-%20Buildings/NFT_57_297_Oxford_Street_London_lyysdi.jpg',
    },
    {
      asset_id: '5b82dbb28a68766a2bcc8632d9b9e024',
      public_id: 'NFT- Buildings/NFT_56_297_Oxford_Street_London_ns9hku',
      format: 'jpg',
      version: 1664176881,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:21Z',
      bytes: 1878077,
      width: 1920,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176881/NFT-%20Buildings/NFT_56_297_Oxford_Street_London_ns9hku.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176881/NFT-%20Buildings/NFT_56_297_Oxford_Street_London_ns9hku.jpg',
    },
    {
      asset_id: 'f0fef31540637b1a609b0cf6b3fa39ce',
      public_id: 'NFT- Buildings/NFT_60_Clothing_Store_250_Oxford_Street_London_eabdzq',
      format: 'jpg',
      version: 1664176881,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:21Z',
      bytes: 789358,
      width: 1920,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176881/NFT-%20Buildings/NFT_60_Clothing_Store_250_Oxford_Street_London_eabdzq.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176881/NFT-%20Buildings/NFT_60_Clothing_Store_250_Oxford_Street_London_eabdzq.jpg',
    },
    {
      asset_id: 'cb542f73c9b0bf1d57316110d9ccfd62',
      public_id: 'NFT- Buildings/NFT_58_Self_Ridges_Oxford_Street_London_taxvtq',
      format: 'jpg',
      version: 1664176881,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:21Z',
      bytes: 691928,
      width: 3228,
      height: 1062,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176881/NFT-%20Buildings/NFT_58_Self_Ridges_Oxford_Street_London_taxvtq.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176881/NFT-%20Buildings/NFT_58_Self_Ridges_Oxford_Street_London_taxvtq.jpg',
    },
    {
      asset_id: 'e1f11e724ceee5c55224522eacfc2cf9',
      public_id: 'NFT- Buildings/NFT_54_221_Oxford_Street_London_txtaf3',
      format: 'jpg',
      version: 1664176881,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:21Z',
      bytes: 1780284,
      width: 1920,
      height: 1002,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176881/NFT-%20Buildings/NFT_54_221_Oxford_Street_London_txtaf3.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176881/NFT-%20Buildings/NFT_54_221_Oxford_Street_London_txtaf3.jpg',
    },
    {
      asset_id: '1244e3bea29144da22395c302586f246',
      public_id: 'NFT- Buildings/NFT_6_W1_Curates_Oxford_Street_London_r4dqzf',
      format: 'jpg',
      version: 1664176880,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:20Z',
      bytes: 236685,
      width: 1326,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176880/NFT-%20Buildings/NFT_6_W1_Curates_Oxford_Street_London_r4dqzf.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176880/NFT-%20Buildings/NFT_6_W1_Curates_Oxford_Street_London_r4dqzf.jpg',
    },
    {
      asset_id: 'ad26791c441e947d32fb9237cc71e403',
      public_id: 'NFT- Buildings/NFT_90_318_Oxford_Street_London_h6yupf',
      format: 'jpg',
      version: 1664176880,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:20Z',
      bytes: 5337585,
      width: 3126,
      height: 2064,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176880/NFT-%20Buildings/NFT_90_318_Oxford_Street_London_h6yupf.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176880/NFT-%20Buildings/NFT_90_318_Oxford_Street_London_h6yupf.jpg',
    },
    {
      asset_id: '91491976b3a9d7aaec3955ea9bf0b4f7',
      public_id: 'NFT- Buildings/NFT_59_Self_Ridges_Oxford_Street_London_dyvs2p',
      format: 'jpg',
      version: 1664176880,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:20Z',
      bytes: 246719,
      width: 1587,
      height: 594,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176880/NFT-%20Buildings/NFT_59_Self_Ridges_Oxford_Street_London_dyvs2p.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176880/NFT-%20Buildings/NFT_59_Self_Ridges_Oxford_Street_London_dyvs2p.jpg',
    },
    {
      asset_id: '200240e1af38e5858bda15ff85aa45e0',
      public_id: 'NFT- Buildings/NFT_44_Zara_clothing_store_333_Oxford_Street_London_England_W1C_2HY_UK_rpfpbg',
      format: 'jpg',
      version: 1664176879,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:19Z',
      bytes: 1146123,
      width: 2435,
      height: 1999,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176879/NFT-%20Buildings/NFT_44_Zara_clothing_store_333_Oxford_Street_London_England_W1C_2HY_UK_rpfpbg.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176879/NFT-%20Buildings/NFT_44_Zara_clothing_store_333_Oxford_Street_London_England_W1C_2HY_UK_rpfpbg.jpg',
    },
    {
      asset_id: 'c7a87e6644fe4e7f6ec1b23700634a45',
      public_id: 'NFT- Buildings/NFT_55_Oxford_Street_London_r1wvss',
      format: 'jpg',
      version: 1664176879,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:19Z',
      bytes: 1591157,
      width: 1553,
      height: 1044,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176879/NFT-%20Buildings/NFT_55_Oxford_Street_London_r1wvss.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176879/NFT-%20Buildings/NFT_55_Oxford_Street_London_r1wvss.jpg',
    },
    {
      asset_id: 'e2d1ce67f7756b1109237d2510cc1094',
      public_id: 'NFT- Buildings/NFT_50_175_Oxford_St_London_W1D_2JS_United_Kingdom_zbvtmi',
      format: 'jpg',
      version: 1664176878,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:18Z',
      bytes: 1305729,
      width: 1454,
      height: 997,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176878/NFT-%20Buildings/NFT_50_175_Oxford_St_London_W1D_2JS_United_Kingdom_zbvtmi.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176878/NFT-%20Buildings/NFT_50_175_Oxford_St_London_W1D_2JS_United_Kingdom_zbvtmi.jpg',
    },
    {
      asset_id: '2da537e91a1f615ddadf2efce2e3ec79',
      public_id: 'NFT- Buildings/NFT_53_309_Oxford_Street_London_dsd77r',
      format: 'jpg',
      version: 1664176878,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:18Z',
      bytes: 849933,
      width: 852,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176878/NFT-%20Buildings/NFT_53_309_Oxford_Street_London_dsd77r.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176878/NFT-%20Buildings/NFT_53_309_Oxford_Street_London_dsd77r.jpg',
    },
    {
      asset_id: '6a29cd6ccdc9bbc7113f2bbf19858bf7',
      public_id: 'NFT- Buildings/NFT_52_309_oxford_street_London_cdw41v',
      format: 'jpg',
      version: 1664176878,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:18Z',
      bytes: 1285396,
      width: 1474,
      height: 1008,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176878/NFT-%20Buildings/NFT_52_309_oxford_street_London_cdw41v.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176878/NFT-%20Buildings/NFT_52_309_oxford_street_London_cdw41v.jpg',
    },
    {
      asset_id: '01cb6b088d986d637c43aa693a2dfdb9',
      public_id: 'NFT- Buildings/NFT_22_McDonald_s_8-10_Oxford_St_London_England_W1D_1AL_United_Kingdom_a9stqb',
      format: 'jpg',
      version: 1664176877,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:17Z',
      bytes: 797148,
      width: 2666,
      height: 2421,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176877/NFT-%20Buildings/NFT_22_McDonald_s_8-10_Oxford_St_London_England_W1D_1AL_United_Kingdom_a9stqb.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176877/NFT-%20Buildings/NFT_22_McDonald_s_8-10_Oxford_St_London_England_W1D_1AL_United_Kingdom_a9stqb.jpg',
    },
    {
      asset_id: '21207e03971782033d405ac6e6944c42',
      public_id: 'NFT- Buildings/NFT_51_175_Oxford_St_London_W1D_2JS_United_Kingdom_z3yf3a',
      format: 'jpg',
      version: 1664176877,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:17Z',
      bytes: 1047763,
      width: 1109,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176877/NFT-%20Buildings/NFT_51_175_Oxford_St_London_W1D_2JS_United_Kingdom_z3yf3a.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176877/NFT-%20Buildings/NFT_51_175_Oxford_St_London_W1D_2JS_United_Kingdom_z3yf3a.jpg',
    },
    {
      asset_id: 'ece9c9525745720b41b734d263ec4004',
      public_id: 'NFT- Buildings/NFT_46_The_Flying_Horse_6_Oxford_St_London_England_W1T_1HJ_UK_cjnhpr',
      format: 'jpg',
      version: 1664176877,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:17Z',
      bytes: 1370611,
      width: 2561,
      height: 2228,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176877/NFT-%20Buildings/NFT_46_The_Flying_Horse_6_Oxford_St_London_England_W1T_1HJ_UK_cjnhpr.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176877/NFT-%20Buildings/NFT_46_The_Flying_Horse_6_Oxford_St_London_England_W1T_1HJ_UK_cjnhpr.jpg',
    },
    {
      asset_id: '7e13eb6487fbcf0b60fd9aa3a3ab8a9f',
      public_id: 'NFT- Buildings/NFT_42_Urban_Outfitters_200_Oxford_St_London_England_W1D_1NU_United_Kingdom_zqji2g',
      format: 'jpg',
      version: 1664176876,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:16Z',
      bytes: 1107395,
      width: 2554,
      height: 1883,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176876/NFT-%20Buildings/NFT_42_Urban_Outfitters_200_Oxford_St_London_England_W1D_1NU_United_Kingdom_zqji2g.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176876/NFT-%20Buildings/NFT_42_Urban_Outfitters_200_Oxford_St_London_England_W1D_1NU_United_Kingdom_zqji2g.jpg',
    },
    {
      asset_id: 'fb7aece410579f90b1d1a2e042c61a67',
      public_id: 'NFT- Buildings/NFT_40_Starbucks_Coffee_112_116_New_Oxford_St_London_WC1A_1HH_UK_bketqs',
      format: 'jpg',
      version: 1664176876,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:16Z',
      bytes: 406965,
      width: 2462,
      height: 2409,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176876/NFT-%20Buildings/NFT_40_Starbucks_Coffee_112_116_New_Oxford_St_London_WC1A_1HH_UK_bketqs.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176876/NFT-%20Buildings/NFT_40_Starbucks_Coffee_112_116_New_Oxford_St_London_WC1A_1HH_UK_bketqs.jpg',
    },
    {
      asset_id: 'fd150320a764211b4b14e02d8af8210f',
      public_id: 'NFT- Buildings/NFT_41_Sunglass_Hut_332_Oxford_Street_London_England_W1C_1JE_United_Kingdom_b1skpp',
      format: 'jpg',
      version: 1664176876,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:16Z',
      bytes: 1129992,
      width: 2728,
      height: 2654,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176876/NFT-%20Buildings/NFT_41_Sunglass_Hut_332_Oxford_Street_London_England_W1C_1JE_United_Kingdom_b1skpp.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176876/NFT-%20Buildings/NFT_41_Sunglass_Hut_332_Oxford_Street_London_England_W1C_1JE_United_Kingdom_b1skpp.jpg',
    },
    {
      asset_id: 'b2c4438634121d9a8e6b8d5b99f299ec',
      public_id: 'NFT- Buildings/NFT_45_Zara_215-219_Oxford_St_London_England_W1D_1NS_United_Kingdom_eaxnzk',
      format: 'jpg',
      version: 1664176875,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:15Z',
      bytes: 458060,
      width: 2610,
      height: 2266,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176875/NFT-%20Buildings/NFT_45_Zara_215-219_Oxford_St_London_England_W1D_1NS_United_Kingdom_eaxnzk.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176875/NFT-%20Buildings/NFT_45_Zara_215-219_Oxford_St_London_England_W1D_1NS_United_Kingdom_eaxnzk.jpg',
    },
    {
      asset_id: 'deeddd2300cb5a2dd0547eb0177e6d62',
      public_id: 'NFT- Buildings/NFT_43_W1_Curates_Art_Gallery_Oxford_Street_London_UK_emlhmc',
      format: 'jpg',
      version: 1664176875,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:15Z',
      bytes: 1832094,
      width: 3367,
      height: 2556,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176875/NFT-%20Buildings/NFT_43_W1_Curates_Art_Gallery_Oxford_Street_London_UK_emlhmc.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176875/NFT-%20Buildings/NFT_43_W1_Curates_Art_Gallery_Oxford_Street_London_UK_emlhmc.jpg',
    },
    {
      asset_id: '5e54d0d84c93ce0f849f561d32709ae5',
      public_id: 'NFT- Buildings/NFT_39_Sports_Direct_150_Oxford_St_London_England_W1D_1NA_United_Kingdom_bvcwum',
      format: 'jpg',
      version: 1664176875,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:15Z',
      bytes: 1050043,
      width: 2670,
      height: 2016,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176875/NFT-%20Buildings/NFT_39_Sports_Direct_150_Oxford_St_London_England_W1D_1NA_United_Kingdom_bvcwum.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176875/NFT-%20Buildings/NFT_39_Sports_Direct_150_Oxford_St_London_England_W1D_1NA_United_Kingdom_bvcwum.jpg',
    },
    {
      asset_id: '310bf1f03e91d434696f8460d98c9ced',
      public_id: 'NFT- Buildings/NFT_31_Ann_Summers_London_Oxford_Street_104-106_Oxford_St_London_W1D_1LP_UK_onxlxt',
      format: 'jpg',
      version: 1664176873,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:13Z',
      bytes: 1118683,
      width: 2771,
      height: 2411,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176873/NFT-%20Buildings/NFT_31_Ann_Summers_London_Oxford_Street_104-106_Oxford_St_London_W1D_1LP_UK_onxlxt.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176873/NFT-%20Buildings/NFT_31_Ann_Summers_London_Oxford_Street_104-106_Oxford_St_London_W1D_1LP_UK_onxlxt.jpg',
    },
    {
      asset_id: 'ebc841ab2c8c3fb80b700051c3e17951',
      public_id: 'NFT- Buildings/NFT_38_Schuh_24_Oxford_Street_London_England_W1D_1AX_UK_ectgob',
      format: 'jpg',
      version: 1664176874,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:14Z',
      bytes: 565634,
      width: 2543,
      height: 2246,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176874/NFT-%20Buildings/NFT_38_Schuh_24_Oxford_Street_London_England_W1D_1AX_UK_ectgob.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176874/NFT-%20Buildings/NFT_38_Schuh_24_Oxford_Street_London_England_W1D_1AX_UK_ectgob.jpg',
    },
    {
      asset_id: '359d78acf4e9851de64e4763487473e3',
      public_id: 'NFT- Buildings/NFT_36_Skechers_42-46_Oxford_St_London_W1D_1AU_UK_gjdyd6',
      format: 'jpg',
      version: 1664176873,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:13Z',
      bytes: 725491,
      width: 2444,
      height: 2119,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176873/NFT-%20Buildings/NFT_36_Skechers_42-46_Oxford_St_London_W1D_1AU_UK_gjdyd6.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176873/NFT-%20Buildings/NFT_36_Skechers_42-46_Oxford_St_London_W1D_1AU_UK_gjdyd6.jpg',
    },
    {
      asset_id: '9adc83e3f657fd0fc2edbc31710e7001',
      public_id: 'NFT- Buildings/NFT_35_Dune_156_Oxford_St_London_England_W1D_1NE_United_Kingdom_k5njmb',
      format: 'jpg',
      version: 1664176873,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:13Z',
      bytes: 1302265,
      width: 2452,
      height: 2070,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176873/NFT-%20Buildings/NFT_35_Dune_156_Oxford_St_London_England_W1D_1NE_United_Kingdom_k5njmb.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176873/NFT-%20Buildings/NFT_35_Dune_156_Oxford_St_London_England_W1D_1NE_United_Kingdom_k5njmb.jpg',
    },
    {
      asset_id: '74afc78edc7b07fe764ab7cc1ad1bb8b',
      public_id: 'NFT- Buildings/NFT_37_Sally_Beauty_78_New_Oxford_Street_London_England_WC1A_1HB_UK_ytxvib',
      format: 'jpg',
      version: 1664176872,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:12Z',
      bytes: 1259222,
      width: 2499,
      height: 2473,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176872/NFT-%20Buildings/NFT_37_Sally_Beauty_78_New_Oxford_Street_London_England_WC1A_1HB_UK_ytxvib.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176872/NFT-%20Buildings/NFT_37_Sally_Beauty_78_New_Oxford_Street_London_England_WC1A_1HB_UK_ytxvib.jpg',
    },
    {
      asset_id: 'd8cc064889cd4672e04a8803d1f7b2d8',
      public_id:
        'NFT- Buildings/NFT_34_Primark_14-28_Oxford_Street_Fitzrovia_London_England_W1D_1AP_United_Kingdom_f07msa',
      format: 'jpg',
      version: 1664176871,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:11Z',
      bytes: 1220995,
      width: 3003,
      height: 2571,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176871/NFT-%20Buildings/NFT_34_Primark_14-28_Oxford_Street_Fitzrovia_London_England_W1D_1AP_United_Kingdom_f07msa.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176871/NFT-%20Buildings/NFT_34_Primark_14-28_Oxford_Street_Fitzrovia_London_England_W1D_1AP_United_Kingdom_f07msa.jpg',
    },
    {
      asset_id: 'afc35aa9f2d7eaa84638d47a260f90f7',
      public_id: 'NFT- Buildings/NFT_33_Office_190_Oxford_St_London_England_W1D_1NR_UK_e4hi9u',
      format: 'jpg',
      version: 1664176871,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:11Z',
      bytes: 932753,
      width: 3045,
      height: 2493,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176871/NFT-%20Buildings/NFT_33_Office_190_Oxford_St_London_England_W1D_1NR_UK_e4hi9u.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176871/NFT-%20Buildings/NFT_33_Office_190_Oxford_St_London_England_W1D_1NR_UK_e4hi9u.jpg',
    },
    {
      asset_id: '3ab3c60cdb28733566ce47c9942bb165',
      public_id: 'NFT- Buildings/NFT_23_Foot_Locker_283_Oxford_Street_London_England_W1C_2DL_United_Kingdom_ozyeke',
      format: 'jpg',
      version: 1664176871,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:11Z',
      bytes: 808746,
      width: 2628,
      height: 2347,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176871/NFT-%20Buildings/NFT_23_Foot_Locker_283_Oxford_Street_London_England_W1C_2DL_United_Kingdom_ozyeke.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176871/NFT-%20Buildings/NFT_23_Foot_Locker_283_Oxford_Street_London_England_W1C_2DL_United_Kingdom_ozyeke.jpg',
    },
    {
      asset_id: '2e4dd9852c2d69abdf444400ad6505a1',
      public_id: 'NFT- Buildings/NFT_30_360-366_Oxford_St_London_W1C_1JN_UK_ip2dh9',
      format: 'jpg',
      version: 1664176870,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:10Z',
      bytes: 994060,
      width: 2055,
      height: 1571,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176870/NFT-%20Buildings/NFT_30_360-366_Oxford_St_London_W1C_1JN_UK_ip2dh9.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176870/NFT-%20Buildings/NFT_30_360-366_Oxford_St_London_W1C_1JN_UK_ip2dh9.jpg',
    },
    {
      asset_id: '9f23fe5fb052412772615da4547ed6b5',
      public_id: 'NFT- Buildings/NFT_28_369_Oxford_St_London_W1C_2JW_United_Kingdom_qoqpyn',
      format: 'jpg',
      version: 1664176870,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:10Z',
      bytes: 825537,
      width: 2185,
      height: 1871,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176870/NFT-%20Buildings/NFT_28_369_Oxford_St_London_W1C_2JW_United_Kingdom_qoqpyn.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176870/NFT-%20Buildings/NFT_28_369_Oxford_St_London_W1C_2JW_United_Kingdom_qoqpyn.jpg',
    },
    {
      asset_id: 'd761f264ce9769b8a0807f0c72aea168',
      public_id: 'NFT- Buildings/NFT_32_O2_351_Oxford_St_London_England_W1C_1JG_United_Kingdom_acjuiy',
      format: 'jpg',
      version: 1664176870,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:10Z',
      bytes: 1581789,
      width: 2875,
      height: 2822,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176870/NFT-%20Buildings/NFT_32_O2_351_Oxford_St_London_England_W1C_1JG_United_Kingdom_acjuiy.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176870/NFT-%20Buildings/NFT_32_O2_351_Oxford_St_London_England_W1C_1JG_United_Kingdom_acjuiy.jpg',
    },
    {
      asset_id: '296c6e14eaad05ad3bbb2b522908ad48',
      public_id: 'NFT- Buildings/NFT_29_Halifax_60_Oxford_St_London_England_W1D_1BN_United_Kingdom_vkhrsl',
      format: 'jpg',
      version: 1664176869,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:09Z',
      bytes: 1748616,
      width: 3305,
      height: 2903,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176869/NFT-%20Buildings/NFT_29_Halifax_60_Oxford_St_London_England_W1D_1BN_United_Kingdom_vkhrsl.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176869/NFT-%20Buildings/NFT_29_Halifax_60_Oxford_St_London_England_W1D_1BN_United_Kingdom_vkhrsl.jpg',
    },
    {
      asset_id: '5302f4f2b43818760c1fbdfcff09a675',
      public_id: 'NFT- Buildings/NFT_27_Boost_Juice_12_Oxford_St_London_England_W1D_1AW_United_Kingdom_hqrsqy',
      format: 'jpg',
      version: 1664176868,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:08Z',
      bytes: 443143,
      width: 2532,
      height: 2260,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176868/NFT-%20Buildings/NFT_27_Boost_Juice_12_Oxford_St_London_England_W1D_1AW_United_Kingdom_hqrsqy.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176868/NFT-%20Buildings/NFT_27_Boost_Juice_12_Oxford_St_London_England_W1D_1AW_United_Kingdom_hqrsqy.jpg',
    },
    {
      asset_id: '4f68fea0b4446bf3f7982a44e4a81dd3',
      public_id: 'NFT- Buildings/NFT_98_419_Oxford_Street_London_lam4ni',
      format: 'jpg',
      version: 1664176868,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:08Z',
      bytes: 3504983,
      width: 2244,
      height: 2034,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176868/NFT-%20Buildings/NFT_98_419_Oxford_Street_London_lam4ni.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176868/NFT-%20Buildings/NFT_98_419_Oxford_Street_London_lam4ni.jpg',
    },
    {
      asset_id: '8d253a7b8da2191d264107a4cd5027c6',
      public_id: 'NFT- Buildings/NFT_94_411_Oxford_Street_London_ctdjve',
      format: 'jpg',
      version: 1664176868,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:08Z',
      bytes: 3181041,
      width: 2598,
      height: 1440,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176868/NFT-%20Buildings/NFT_94_411_Oxford_Street_London_ctdjve.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176868/NFT-%20Buildings/NFT_94_411_Oxford_Street_London_ctdjve.jpg',
    },
    {
      asset_id: 'ef366d506e6463036be3b6cf6c96b59a',
      public_id: 'NFT- Buildings/NFT_26_Londis_51_Oxford_St_London_W1D_2EF_UK_p9lvyz',
      format: 'jpg',
      version: 1664176867,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:07Z',
      bytes: 554076,
      width: 2497,
      height: 2258,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176867/NFT-%20Buildings/NFT_26_Londis_51_Oxford_St_London_W1D_2EF_UK_p9lvyz.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176867/NFT-%20Buildings/NFT_26_Londis_51_Oxford_St_London_W1D_2EF_UK_p9lvyz.jpg',
    },
    {
      asset_id: '9d707d20f59099b62ea2e417ec15c6a1',
      public_id:
        'NFT- Buildings/NFT_25_ICE-International_Currency_Exchange_241_Oxford_St_London_England_W1D_2LU_United_Kingdom_hrd8ok',
      format: 'jpg',
      version: 1664176867,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:07Z',
      bytes: 838934,
      width: 3008,
      height: 2905,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176867/NFT-%20Buildings/NFT_25_ICE-International_Currency_Exchange_241_Oxford_St_London_England_W1D_2LU_United_Kingdom_hrd8ok.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176867/NFT-%20Buildings/NFT_25_ICE-International_Currency_Exchange_241_Oxford_St_London_England_W1D_2LU_United_Kingdom_hrd8ok.jpg',
    },
    {
      asset_id: 'cfea3dfaa58715cb8dc5cf15b84e6e8f',
      public_id: 'NFT- Buildings/NFT_97_425_Oxford_Street_London_gcpfwu',
      format: 'jpg',
      version: 1664176867,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:07Z',
      bytes: 3610863,
      width: 2004,
      height: 2058,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176867/NFT-%20Buildings/NFT_97_425_Oxford_Street_London_gcpfwu.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176867/NFT-%20Buildings/NFT_97_425_Oxford_Street_London_gcpfwu.jpg',
    },
    {
      asset_id: '9ab80ad21ca7465b7b3855d3cd15c94f',
      public_id: 'NFT- Buildings/NFT_24_Matalan_149-151_Oxford_St_London_W1D_2JQ_UK_dm25zm',
      format: 'jpg',
      version: 1664176866,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:06Z',
      bytes: 1169050,
      width: 3091,
      height: 2522,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176866/NFT-%20Buildings/NFT_24_Matalan_149-151_Oxford_St_London_W1D_2JQ_UK_dm25zm.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176866/NFT-%20Buildings/NFT_24_Matalan_149-151_Oxford_St_London_W1D_2JQ_UK_dm25zm.jpg',
    },
    {
      asset_id: 'f0bfdf53cc68ea6dec409b99c2c3b08b',
      public_id: 'NFT- Buildings/NFT_21_Flight_Club_100_new_Oxford_Str_London_England_WC1A_1BS_UK_dzpcjt',
      format: 'jpg',
      version: 1664176865,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:05Z',
      bytes: 847863,
      width: 2645,
      height: 2211,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176865/NFT-%20Buildings/NFT_21_Flight_Club_100_new_Oxford_Str_London_England_WC1A_1BS_UK_dzpcjt.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176865/NFT-%20Buildings/NFT_21_Flight_Club_100_new_Oxford_Str_London_England_WC1A_1BS_UK_dzpcjt.jpg',
    },
    {
      asset_id: '508835e04f6ed6d798f7abc1883ce026',
      public_id: 'NFT- Buildings/NFT_20_Lloyds_Bank_115-117_Oxford_Street_London_England_W1D_2HP_United_Kingdom_epqo8s',
      format: 'jpg',
      version: 1664176865,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:05Z',
      bytes: 1541357,
      width: 2740,
      height: 2031,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176865/NFT-%20Buildings/NFT_20_Lloyds_Bank_115-117_Oxford_Street_London_England_W1D_2HP_United_Kingdom_epqo8s.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176865/NFT-%20Buildings/NFT_20_Lloyds_Bank_115-117_Oxford_Street_London_England_W1D_2HP_United_Kingdom_epqo8s.jpg',
    },
    {
      asset_id: '7b3e018a3e50c7713fa066110bb45360',
      public_id: 'NFT- Buildings/NFT_19_JD_Sports_197-203_Oxford_Street_London_England_W1F_7TY_United_Kingdom_fcmiep',
      format: 'jpg',
      version: 1664176864,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:04Z',
      bytes: 1722930,
      width: 3367,
      height: 2452,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176864/NFT-%20Buildings/NFT_19_JD_Sports_197-203_Oxford_Street_London_England_W1F_7TY_United_Kingdom_fcmiep.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176864/NFT-%20Buildings/NFT_19_JD_Sports_197-203_Oxford_Street_London_England_W1F_7TY_United_Kingdom_fcmiep.jpg',
    },
    {
      asset_id: 'c2ec0b41a4c8cddc4a0ba27942495ea9',
      public_id: 'NFT- Buildings/NFT_18_Next_120-128_Oxford_Street_London_England_W1D_1LT_United_Kingdom_xaoti2',
      format: 'jpg',
      version: 1664176864,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:04Z',
      bytes: 1180884,
      width: 2925,
      height: 2203,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176864/NFT-%20Buildings/NFT_18_Next_120-128_Oxford_Street_London_England_W1D_1LT_United_Kingdom_xaoti2.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176864/NFT-%20Buildings/NFT_18_Next_120-128_Oxford_Street_London_England_W1D_1LT_United_Kingdom_xaoti2.jpg',
    },
    {
      asset_id: 'd13637e98ce1b9b7fa974d2c8bbe7307',
      public_id: 'NFT- Buildings/NFT_17_McDonald_s_291B_Oxford_Street_W1C_2DT_UK_hikge8',
      format: 'jpg',
      version: 1664176863,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:03Z',
      bytes: 1430422,
      width: 3117,
      height: 2351,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176863/NFT-%20Buildings/NFT_17_McDonald_s_291B_Oxford_Street_W1C_2DT_UK_hikge8.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176863/NFT-%20Buildings/NFT_17_McDonald_s_291B_Oxford_Street_W1C_2DT_UK_hikge8.jpg',
    },
    {
      asset_id: '94003eb6abfa08c8cf7705c02e35eaf2',
      public_id: 'NFT- Buildings/NFT_91_318_Oxford_Street_London_durzcm',
      format: 'jpg',
      version: 1664176863,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:03Z',
      bytes: 5068019,
      width: 3174,
      height: 2160,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176863/NFT-%20Buildings/NFT_91_318_Oxford_Street_London_durzcm.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176863/NFT-%20Buildings/NFT_91_318_Oxford_Street_London_durzcm.jpg',
    },
    {
      asset_id: 'e70f4c26023ab418bd36fd1e78e5b507',
      public_id: 'NFT- Buildings/NFT_11_361_Oxford_Street_London_th38i5',
      format: 'jpg',
      version: 1664176863,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:03Z',
      bytes: 157283,
      width: 951,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176863/NFT-%20Buildings/NFT_11_361_Oxford_Street_London_th38i5.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176863/NFT-%20Buildings/NFT_11_361_Oxford_Street_London_th38i5.jpg',
    },
    {
      asset_id: '61da501f07f0ea004e55b942209e7b78',
      public_id: 'NFT- Buildings/NFT_13_7_Shallow_Place_W1B_2AG_UK_czqnm7',
      format: 'jpg',
      version: 1664176861,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:01Z',
      bytes: 1500663,
      width: 3367,
      height: 2634,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176861/NFT-%20Buildings/NFT_13_7_Shallow_Place_W1B_2AG_UK_czqnm7.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176861/NFT-%20Buildings/NFT_13_7_Shallow_Place_W1B_2AG_UK_czqnm7.jpg',
    },
    {
      asset_id: 'd39f40f815eec15dd13905568131ec79',
      public_id: 'NFT- Buildings/NFT_103_325_Oxford_Street_London_is25ol',
      format: 'jpg',
      version: 1664176861,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:01Z',
      bytes: 4822393,
      width: 2502,
      height: 2076,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176861/NFT-%20Buildings/NFT_103_325_Oxford_Street_London_is25ol.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176861/NFT-%20Buildings/NFT_103_325_Oxford_Street_London_is25ol.jpg',
    },
    {
      asset_id: 'd5bc36743df5c2d8f49ecf57ea4809ff',
      public_id: 'NFT- Buildings/NFT_99_419_Oxford_STreet_London_eyauuz',
      format: 'jpg',
      version: 1664176860,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:21:00Z',
      bytes: 3810005,
      width: 2202,
      height: 2040,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176860/NFT-%20Buildings/NFT_99_419_Oxford_STreet_London_eyauuz.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176860/NFT-%20Buildings/NFT_99_419_Oxford_STreet_London_eyauuz.jpg',
    },
    {
      asset_id: 'e4ff9dd6aaca3235e2523400f13ff132',
      public_id: 'NFT- Buildings/NFT_96_425_Oxfors_Street_London_r9vny6',
      format: 'jpg',
      version: 1664176859,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:59Z',
      bytes: 3004445,
      width: 1920,
      height: 1968,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176859/NFT-%20Buildings/NFT_96_425_Oxfors_Street_London_r9vny6.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176859/NFT-%20Buildings/NFT_96_425_Oxfors_Street_London_r9vny6.jpg',
    },
    {
      asset_id: '183dab11eb6b9743cd11e47920cd0846',
      public_id: 'NFT- Buildings/NFT_15_Spaces_London_Mappin_House_Oxford_Street_London_England_W1D_1NF_dovasi',
      format: 'jpg',
      version: 1664176857,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:57Z',
      bytes: 1487923,
      width: 2494,
      height: 2147,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176857/NFT-%20Buildings/NFT_15_Spaces_London_Mappin_House_Oxford_Street_London_England_W1D_1NF_dovasi.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176857/NFT-%20Buildings/NFT_15_Spaces_London_Mappin_House_Oxford_Street_London_England_W1D_1NF_dovasi.jpg',
    },
    {
      asset_id: '5f787a686cd3fd76a20c26838f00187a',
      public_id: 'NFT- Buildings/NFT_101_221-223_Oxford_Street_London_gdmeb6',
      format: 'jpg',
      version: 1664176857,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:57Z',
      bytes: 3209751,
      width: 1596,
      height: 1956,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176857/NFT-%20Buildings/NFT_101_221-223_Oxford_Street_London_gdmeb6.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176857/NFT-%20Buildings/NFT_101_221-223_Oxford_Street_London_gdmeb6.jpg',
    },
    {
      asset_id: 'c22491733054a1f43b5f430495a14531',
      public_id: 'NFT- Buildings/NFT_95_411_Oxford_Street_London_wnqiwb',
      format: 'jpg',
      version: 1664176857,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:57Z',
      bytes: 3961411,
      width: 2664,
      height: 1536,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176857/NFT-%20Buildings/NFT_95_411_Oxford_Street_London_wnqiwb.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176857/NFT-%20Buildings/NFT_95_411_Oxford_Street_London_wnqiwb.jpg',
    },
    {
      asset_id: 'dc301aee43ed7b17634f476ced7a7468',
      public_id: 'NFT- Buildings/NFT_3_112-114_Oxford_street_London_mxf895',
      format: 'jpg',
      version: 1664176856,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:56Z',
      bytes: 2403418,
      width: 6240,
      height: 4320,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176856/NFT-%20Buildings/NFT_3_112-114_Oxford_street_London_mxf895.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176856/NFT-%20Buildings/NFT_3_112-114_Oxford_street_London_mxf895.jpg',
    },
    {
      asset_id: '575ac53047b24fb1e3bf519205790299',
      public_id: 'NFT- Buildings/NFT_89_HSBC_196_Oxford_Street_London_caiqir',
      format: 'jpg',
      version: 1664176855,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:55Z',
      bytes: 2602555,
      width: 1524,
      height: 2082,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176855/NFT-%20Buildings/NFT_89_HSBC_196_Oxford_Street_London_caiqir.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176855/NFT-%20Buildings/NFT_89_HSBC_196_Oxford_Street_London_caiqir.jpg',
    },
    {
      asset_id: '1b365c97a441c12714a80b78ed0abded',
      public_id: 'NFT- Buildings/NFT_14_Accessorize_55_Oxford_S_London_England_W1D_2EQ_United_Kingdom_x5akkn',
      format: 'jpg',
      version: 1664176854,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:54Z',
      bytes: 1322190,
      width: 3061,
      height: 2963,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176854/NFT-%20Buildings/NFT_14_Accessorize_55_Oxford_S_London_England_W1D_2EQ_United_Kingdom_x5akkn.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176854/NFT-%20Buildings/NFT_14_Accessorize_55_Oxford_S_London_England_W1D_2EQ_United_Kingdom_x5akkn.jpg',
    },
    {
      asset_id: '37a3ce0141cb72bb1996dee3940cf4e0',
      public_id: 'NFT- Buildings/NFT_93_390_Oxford_Street_London_t9nxxo',
      format: 'jpg',
      version: 1664176854,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:54Z',
      bytes: 3791578,
      width: 2298,
      height: 2028,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176854/NFT-%20Buildings/NFT_93_390_Oxford_Street_London_t9nxxo.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176854/NFT-%20Buildings/NFT_93_390_Oxford_Street_London_t9nxxo.jpg',
    },
    {
      asset_id: '2dffd4a91efc06b5f34b3ad96ad80bb8',
      public_id: 'NFT- Buildings/NFT_92_390_Oxford_Street_London_tb6jjo',
      format: 'jpg',
      version: 1664176853,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:53Z',
      bytes: 2955298,
      width: 2070,
      height: 2034,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176853/NFT-%20Buildings/NFT_92_390_Oxford_Street_London_tb6jjo.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176853/NFT-%20Buildings/NFT_92_390_Oxford_Street_London_tb6jjo.jpg',
    },
    {
      asset_id: 'aad39206263c61f0fe0867a75c06905c',
      public_id: 'NFT- Buildings/NFT_2_112-114_Oxford_street_London_muzc6n',
      format: 'jpg',
      version: 1664176851,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:51Z',
      bytes: 2772891,
      width: 6480,
      height: 4320,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176851/NFT-%20Buildings/NFT_2_112-114_Oxford_street_London_muzc6n.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176851/NFT-%20Buildings/NFT_2_112-114_Oxford_street_London_muzc6n.jpg',
    },
    {
      asset_id: '6e92a3cd78e9ee8c0f3b9ff6f2a0d34e',
      public_id: 'NFT- Buildings/NFT_88_HSBC_196_Oxford_Street_London_rjojuj',
      format: 'jpg',
      version: 1664176851,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:51Z',
      bytes: 2738423,
      width: 1716,
      height: 2046,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176851/NFT-%20Buildings/NFT_88_HSBC_196_Oxford_Street_London_rjojuj.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176851/NFT-%20Buildings/NFT_88_HSBC_196_Oxford_Street_London_rjojuj.jpg',
    },
    {
      asset_id: '69a93a9f0f8145a02749bbb48d6b07a7',
      public_id: 'NFT- Buildings/NFT_5_112-114_Oxford_street_London_t9snva',
      format: 'jpg',
      version: 1664176850,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:50Z',
      bytes: 3030253,
      width: 5532,
      height: 4320,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176850/NFT-%20Buildings/NFT_5_112-114_Oxford_street_London_t9snva.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176850/NFT-%20Buildings/NFT_5_112-114_Oxford_street_London_t9snva.jpg',
    },
    {
      asset_id: '2542a9fb1acbc4ec16f66a28f39ec6f6',
      public_id: 'NFT- Buildings/NFT_16_Office_for_rent_145_Oxford_St_London_W1D_2JD_UK_qomqfz',
      format: 'jpg',
      version: 1664176850,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:50Z',
      bytes: 2227992,
      width: 3570,
      height: 3123,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176850/NFT-%20Buildings/NFT_16_Office_for_rent_145_Oxford_St_London_W1D_2JD_UK_qomqfz.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176850/NFT-%20Buildings/NFT_16_Office_for_rent_145_Oxford_St_London_W1D_2JD_UK_qomqfz.jpg',
    },
    {
      asset_id: '79f66c6564333bb06386a08d6647d024',
      public_id: 'NFT- Buildings/NFT_106_227_Oxford_Street_London_molqls',
      format: 'jpg',
      version: 1664176848,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:48Z',
      bytes: 3503489,
      width: 2082,
      height: 2094,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176848/NFT-%20Buildings/NFT_106_227_Oxford_Street_London_molqls.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176848/NFT-%20Buildings/NFT_106_227_Oxford_Street_London_molqls.jpg',
    },
    {
      asset_id: 'cf187cd134034a65fe7c3fc85af3bee4',
      public_id: 'NFT- Buildings/NFT_12_361_Oxford_Street_London_lirunh',
      format: 'jpg',
      version: 1664176848,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:48Z',
      bytes: 146201,
      width: 933,
      height: 1050,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176848/NFT-%20Buildings/NFT_12_361_Oxford_Street_London_lirunh.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176848/NFT-%20Buildings/NFT_12_361_Oxford_Street_London_lirunh.jpg',
    },
    {
      asset_id: '6812d8e50de43c955b64c5c82bdb350e',
      public_id: 'NFT- Buildings/NFT_100_318_Oxford_Street_London_uhqqoy',
      format: 'jpg',
      version: 1664176847,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:47Z',
      bytes: 3157798,
      width: 2408,
      height: 1560,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176847/NFT-%20Buildings/NFT_100_318_Oxford_Street_London_uhqqoy.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176847/NFT-%20Buildings/NFT_100_318_Oxford_Street_London_uhqqoy.jpg',
    },
    {
      asset_id: 'f6441e7fee9057b9c938ee5f4429ab26',
      public_id: 'NFT- Buildings/NFT_1_112-114_Oxford_street_London_blyguv',
      format: 'jpg',
      version: 1664176846,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:46Z',
      bytes: 3641559,
      width: 5516,
      height: 4320,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176846/NFT-%20Buildings/NFT_1_112-114_Oxford_street_London_blyguv.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176846/NFT-%20Buildings/NFT_1_112-114_Oxford_street_London_blyguv.jpg',
    },
    {
      asset_id: '186456dc75e24debfbeada9fcdb25932',
      public_id: 'NFT- Buildings/NFT_105_227_Oxford_Street_London_pqirfs',
      format: 'jpg',
      version: 1664176846,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:46Z',
      bytes: 3716577,
      width: 2112,
      height: 2052,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176846/NFT-%20Buildings/NFT_105_227_Oxford_Street_London_pqirfs.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176846/NFT-%20Buildings/NFT_105_227_Oxford_Street_London_pqirfs.jpg',
    },
    {
      asset_id: '85d4c464b0d4935d9b1a272b12e6b4cc',
      public_id: 'NFT- Buildings/NFT_102_221-223_Oxford_Street_London_jgyd0b',
      format: 'jpg',
      version: 1664176846,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:46Z',
      bytes: 2801646,
      width: 1818,
      height: 1980,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176846/NFT-%20Buildings/NFT_102_221-223_Oxford_Street_London_jgyd0b.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176846/NFT-%20Buildings/NFT_102_221-223_Oxford_Street_London_jgyd0b.jpg',
    },
    {
      asset_id: '9551ee2c99149fcaf7d159aa840bbf7c',
      public_id: 'NFT- Buildings/NFT_104_325_Oxford_Street_London_o4jxsn',
      format: 'jpg',
      version: 1664176845,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:45Z',
      bytes: 3379064,
      width: 2454,
      height: 2064,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176845/NFT-%20Buildings/NFT_104_325_Oxford_Street_London_o4jxsn.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176845/NFT-%20Buildings/NFT_104_325_Oxford_Street_London_o4jxsn.jpg',
    },
    {
      asset_id: '8eeaecfd5837a91fd17761db7a633f3e',
      public_id: 'NFT- Buildings/NFT_9_365_Oxford_Street_London_i4rdjr',
      format: 'jpg',
      version: 1664176843,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:43Z',
      bytes: 136829,
      width: 1035,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176843/NFT-%20Buildings/NFT_9_365_Oxford_Street_London_i4rdjr.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176843/NFT-%20Buildings/NFT_9_365_Oxford_Street_London_i4rdjr.jpg',
    },
    {
      asset_id: 'eb606d21a32821aa332c4d98799d8359',
      public_id: 'NFT- Buildings/NFT_83_321_Oxford_St_London_W1C_2HR_United_Kingdom_ukjbcy',
      format: 'jpg',
      version: 1664176843,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:43Z',
      bytes: 1492874,
      width: 2400,
      height: 2016,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176843/NFT-%20Buildings/NFT_83_321_Oxford_St_London_W1C_2HR_United_Kingdom_ukjbcy.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176843/NFT-%20Buildings/NFT_83_321_Oxford_St_London_W1C_2HR_United_Kingdom_ukjbcy.jpg',
    },
    {
      asset_id: 'e20f80a72e808574e865993ad7b6350a',
      public_id: 'NFT- Buildings/NFT_87_315_Oxford_St_London_W1C_2HX_United_Kingdom_wujmgk',
      format: 'jpg',
      version: 1664176842,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:42Z',
      bytes: 1351748,
      width: 2640,
      height: 2086,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176842/NFT-%20Buildings/NFT_87_315_Oxford_St_London_W1C_2HX_United_Kingdom_wujmgk.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176842/NFT-%20Buildings/NFT_87_315_Oxford_St_London_W1C_2HX_United_Kingdom_wujmgk.jpg',
    },
    {
      asset_id: 'ba6819c4be7fb0ed541d993c2ac474a3',
      public_id: 'NFT- Buildings/NFT_110_353_Oxford_St_London_W1C_2JG_United_Kingdom_rmpklx',
      format: 'jpg',
      version: 1664176842,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:42Z',
      bytes: 768522,
      width: 1976,
      height: 2161,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176842/NFT-%20Buildings/NFT_110_353_Oxford_St_London_W1C_2JG_United_Kingdom_rmpklx.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176842/NFT-%20Buildings/NFT_110_353_Oxford_St_London_W1C_2JG_United_Kingdom_rmpklx.jpg',
    },
    {
      asset_id: '38d0075fa5a6e402d10bc1be240300df',
      public_id: 'NFT- Buildings/NFT_86_173_Oxford_St_London_W1D_2JR_United_Kingdom_w7maxt',
      format: 'jpg',
      version: 1664176842,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:42Z',
      bytes: 1217654,
      width: 3051,
      height: 2001,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176842/NFT-%20Buildings/NFT_86_173_Oxford_St_London_W1D_2JR_United_Kingdom_w7maxt.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176842/NFT-%20Buildings/NFT_86_173_Oxford_St_London_W1D_2JR_United_Kingdom_w7maxt.jpg',
    },
    {
      asset_id: '2e8f77e8c632d81d05555ecbc296dbc7',
      public_id: 'NFT- Buildings/NFT_84_395_Oxford_St_London_W1C_2JX_United_Kingdom_u07dfk',
      format: 'jpg',
      version: 1664176842,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:42Z',
      bytes: 1181529,
      width: 2464,
      height: 1922,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176842/NFT-%20Buildings/NFT_84_395_Oxford_St_London_W1C_2JX_United_Kingdom_u07dfk.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176842/NFT-%20Buildings/NFT_84_395_Oxford_St_London_W1C_2JX_United_Kingdom_u07dfk.jpg',
    },
    {
      asset_id: 'b48c54573114e272de233e71cfe4bff9',
      public_id: 'NFT- Buildings/NFT_85_225_Oxford_St_London_W1D_2LR_UK_t7tqmm',
      format: 'jpg',
      version: 1664176841,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:41Z',
      bytes: 676448,
      width: 1947,
      height: 1791,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176841/NFT-%20Buildings/NFT_85_225_Oxford_St_London_W1D_2LR_UK_t7tqmm.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176841/NFT-%20Buildings/NFT_85_225_Oxford_St_London_W1D_2LR_UK_t7tqmm.jpg',
    },
    {
      asset_id: '22c35c1b104f06bd7f23aec17a0d182a',
      public_id: 'NFT- Buildings/NFT_80_425_Oxford_Street_London_aibbzu',
      format: 'jpg',
      version: 1664176841,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:41Z',
      bytes: 1287399,
      width: 1539,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176841/NFT-%20Buildings/NFT_80_425_Oxford_Street_London_aibbzu.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176841/NFT-%20Buildings/NFT_80_425_Oxford_Street_London_aibbzu.jpg',
    },
    {
      asset_id: '0d17dddd9519aad992b52f5f19bec76e',
      public_id: 'NFT- Buildings/NFT_82_311_Oxford_St_London_W1C_2HP_UK_g0tlth',
      format: 'jpg',
      version: 1664176841,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:41Z',
      bytes: 789930,
      width: 2342,
      height: 1932,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176841/NFT-%20Buildings/NFT_82_311_Oxford_St_London_W1C_2HP_UK_g0tlth.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176841/NFT-%20Buildings/NFT_82_311_Oxford_St_London_W1C_2HP_UK_g0tlth.jpg',
    },
    {
      asset_id: 'e45f10ffcfe95b04b7944706f8195e0c',
      public_id: 'NFT- Buildings/NFT_81_425_Oxford_Street_London_fggyre',
      format: 'jpg',
      version: 1664176840,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:40Z',
      bytes: 833511,
      width: 945,
      height: 1024,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176840/NFT-%20Buildings/NFT_81_425_Oxford_Street_London_fggyre.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176840/NFT-%20Buildings/NFT_81_425_Oxford_Street_London_fggyre.jpg',
    },
    {
      asset_id: '87195f5ce4b0974d817e5569f8510230',
      public_id: 'NFT- Buildings/NFT_77_411_Oxford_Street_London_jetrtb',
      format: 'jpg',
      version: 1664176840,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:40Z',
      bytes: 1367184,
      width: 1480,
      height: 978,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176840/NFT-%20Buildings/NFT_77_411_Oxford_Street_London_jetrtb.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176840/NFT-%20Buildings/NFT_77_411_Oxford_Street_London_jetrtb.jpg',
    },
    {
      asset_id: '4ea4c6f0d19849a7efa5a8cc5cce78ff',
      public_id: 'NFT- Buildings/NFT_79_411_Oxford_Street_London_qm4ri2',
      format: 'jpg',
      version: 1664176839,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:39Z',
      bytes: 787837,
      width: 1280,
      height: 720,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176839/NFT-%20Buildings/NFT_79_411_Oxford_Street_London_qm4ri2.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176839/NFT-%20Buildings/NFT_79_411_Oxford_Street_London_qm4ri2.jpg',
    },
    {
      asset_id: '09957ff2d456842e6834b3a8821d1824',
      public_id: 'NFT- Buildings/NFT_8_W1_curates_Oxford_Street_London_cnh0i9',
      format: 'jpg',
      version: 1664176839,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:39Z',
      bytes: 201047,
      width: 1218,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176839/NFT-%20Buildings/NFT_8_W1_curates_Oxford_Street_London_cnh0i9.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176839/NFT-%20Buildings/NFT_8_W1_curates_Oxford_Street_London_cnh0i9.jpg',
    },
    {
      asset_id: 'aa450d6ab198759e7a1fa2238f973cee',
      public_id: 'NFT- Buildings/NFT_78_411_Oxford_Street_London_tpedgw',
      format: 'jpg',
      version: 1664176839,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:39Z',
      bytes: 1073270,
      width: 1512,
      height: 858,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176839/NFT-%20Buildings/NFT_78_411_Oxford_Street_London_tpedgw.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176839/NFT-%20Buildings/NFT_78_411_Oxford_Street_London_tpedgw.jpg',
    },
    {
      asset_id: '25d1b29c9f59677172cac132f8c8def0',
      public_id: 'NFT- Buildings/NFT_108_146-148_Oxford_St_London_W1D_1NB_United_Kingdom_dwkelz',
      format: 'jpg',
      version: 1664176838,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:38Z',
      bytes: 1151304,
      width: 2360,
      height: 1734,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176838/NFT-%20Buildings/NFT_108_146-148_Oxford_St_London_W1D_1NB_United_Kingdom_dwkelz.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176838/NFT-%20Buildings/NFT_108_146-148_Oxford_St_London_W1D_1NB_United_Kingdom_dwkelz.jpg',
    },
    {
      asset_id: 'b4ff788d1c3373b0638e78961e64e046',
      public_id: 'NFT- Buildings/NFT_75_390_Oxford_Street_London_iypevm',
      format: 'jpg',
      version: 1664176838,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:38Z',
      bytes: 939678,
      width: 1130,
      height: 977,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176838/NFT-%20Buildings/NFT_75_390_Oxford_Street_London_iypevm.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176838/NFT-%20Buildings/NFT_75_390_Oxford_Street_London_iypevm.jpg',
    },
    {
      asset_id: 'f27a341321d443be0c4fb477e67d4f8d',
      public_id: 'NFT- Buildings/NFT_109_174-180_Oxford_St_London_W1D_1NQ_UK_awzqfc',
      format: 'jpg',
      version: 1664176838,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:38Z',
      bytes: 1660901,
      width: 2640,
      height: 1970,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176838/NFT-%20Buildings/NFT_109_174-180_Oxford_St_London_W1D_1NQ_UK_awzqfc.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176838/NFT-%20Buildings/NFT_109_174-180_Oxford_St_London_W1D_1NQ_UK_awzqfc.jpg',
    },
    {
      asset_id: '774aa5899eb3c7db8dbef403b505a58d',
      public_id: 'NFT- Buildings/NFT_74_390_Oxford_Street_London_ryyogh',
      format: 'jpg',
      version: 1664176838,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:38Z',
      bytes: 1114378,
      width: 1333,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176838/NFT-%20Buildings/NFT_74_390_Oxford_Street_London_ryyogh.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176838/NFT-%20Buildings/NFT_74_390_Oxford_Street_London_ryyogh.jpg',
    },
    {
      asset_id: 'c3d56fdba8f96adf07aaaffba786552b',
      public_id: 'NFT- Buildings/NFT_76_390_Oxford_Street_London_jqhdx2',
      format: 'jpg',
      version: 1664176837,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:37Z',
      bytes: 778182,
      width: 1068,
      height: 978,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176837/NFT-%20Buildings/NFT_76_390_Oxford_Street_London_jqhdx2.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176837/NFT-%20Buildings/NFT_76_390_Oxford_Street_London_jqhdx2.jpg',
    },
    {
      asset_id: 'dbf0e81ce8772dc8fff6a912050477de',
      public_id: 'NFT- Buildings/NFT_64_Clothing_Store_250_Oxford_Street_London_gkazad',
      format: 'jpg',
      version: 1664176837,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:37Z',
      bytes: 1111564,
      width: 1920,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176837/NFT-%20Buildings/NFT_64_Clothing_Store_250_Oxford_Street_London_gkazad.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176837/NFT-%20Buildings/NFT_64_Clothing_Store_250_Oxford_Street_London_gkazad.jpg',
    },
    {
      asset_id: '0de8bd1d4c459826e5dc94f07d5f9303',
      public_id: 'NFT- Buildings/NFT_70_112-114_Oxford_street_London_W1D_1LS_hnr93t',
      format: 'jpg',
      version: 1664176837,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:37Z',
      bytes: 897979,
      width: 3222,
      height: 2059,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176837/NFT-%20Buildings/NFT_70_112-114_Oxford_street_London_W1D_1LS_hnr93t.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176837/NFT-%20Buildings/NFT_70_112-114_Oxford_street_London_W1D_1LS_hnr93t.jpg',
    },
    {
      asset_id: 'fa45d45e61f92fdb2257caac088c6a41',
      public_id: 'NFT- Buildings/NFT_7_W1_Curaes_Oxford_Street_London_hfpiye',
      format: 'jpg',
      version: 1664176837,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:37Z',
      bytes: 102125,
      width: 1280,
      height: 720,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176837/NFT-%20Buildings/NFT_7_W1_Curaes_Oxford_Street_London_hfpiye.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176837/NFT-%20Buildings/NFT_7_W1_Curaes_Oxford_Street_London_hfpiye.jpg',
    },
    {
      asset_id: '6b106c65e9b0812d958d0c0019f09039',
      public_id: 'NFT- Buildings/NFT_67_Oxford_Street_London_hlogd7',
      format: 'jpg',
      version: 1664176836,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:36Z',
      bytes: 129839,
      width: 779,
      height: 559,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176836/NFT-%20Buildings/NFT_67_Oxford_Street_London_hlogd7.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176836/NFT-%20Buildings/NFT_67_Oxford_Street_London_hlogd7.jpg',
    },
    {
      asset_id: '24a67576f28084d325033661cf50b760',
      public_id: 'NFT- Buildings/NFT_69_Oxford_Street_London_crxiaf',
      format: 'jpg',
      version: 1664176836,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:36Z',
      bytes: 136475,
      width: 1017,
      height: 473,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176836/NFT-%20Buildings/NFT_69_Oxford_Street_London_crxiaf.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176836/NFT-%20Buildings/NFT_69_Oxford_Street_London_crxiaf.jpg',
    },
    {
      asset_id: '413d4bc4c0b190d625905b8b74b1c578',
      public_id: 'NFT- Buildings/NFT_68_Oxford_Street_London_vcbubc',
      format: 'jpg',
      version: 1664176836,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:36Z',
      bytes: 101022,
      width: 888,
      height: 524,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176836/NFT-%20Buildings/NFT_68_Oxford_Street_London_vcbubc.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176836/NFT-%20Buildings/NFT_68_Oxford_Street_London_vcbubc.jpg',
    },
    {
      asset_id: '4df9b84e50158a62d1e08d5858d4953a',
      public_id: 'NFT- Buildings/NFT_65_Clothing_Store_250_Oxford_Street_London_vsm3mh',
      format: 'jpg',
      version: 1664176835,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:35Z',
      bytes: 759047,
      width: 1920,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176835/NFT-%20Buildings/NFT_65_Clothing_Store_250_Oxford_Street_London_vsm3mh.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176835/NFT-%20Buildings/NFT_65_Clothing_Store_250_Oxford_Street_London_vsm3mh.jpg',
    },
    {
      asset_id: '9f17642ec2c7b94ebc4ad5c831e2b6d2',
      public_id: 'NFT- Buildings/NFT_63_Clothing_Store_250_Oxford_Street_London_p4i434',
      format: 'jpg',
      version: 1664176835,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:35Z',
      bytes: 653236,
      width: 1920,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176835/NFT-%20Buildings/NFT_63_Clothing_Store_250_Oxford_Street_London_p4i434.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176835/NFT-%20Buildings/NFT_63_Clothing_Store_250_Oxford_Street_London_p4i434.jpg',
    },
    {
      asset_id: 'ff943dbce6c89d4485ca8e7d9644be29',
      public_id: 'NFT- Buildings/NFT_66_Self_Ridges_Oxford_Street_London_l6dcmk',
      format: 'jpg',
      version: 1664176835,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:35Z',
      bytes: 1001643,
      width: 1593,
      height: 894,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176835/NFT-%20Buildings/NFT_66_Self_Ridges_Oxford_Street_London_l6dcmk.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176835/NFT-%20Buildings/NFT_66_Self_Ridges_Oxford_Street_London_l6dcmk.jpg',
    },
    {
      asset_id: 'cdf9a3fbe9cc2235308df5ec927ffd78',
      public_id: 'NFT- Buildings/NFT_107_241_Oxford_St_London_W1D_2LT_UK_cvujzq',
      format: 'jpg',
      version: 1664176835,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:35Z',
      bytes: 1615506,
      width: 2284,
      height: 1956,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176835/NFT-%20Buildings/NFT_107_241_Oxford_St_London_W1D_2LT_UK_cvujzq.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176835/NFT-%20Buildings/NFT_107_241_Oxford_St_London_W1D_2LT_UK_cvujzq.jpg',
    },
    {
      asset_id: 'c17e0ba949013e17045d0813663a153b',
      public_id: 'NFT- Buildings/NFT_62_Clothing_Store_250_Oxford_Street_London_lpxbws',
      format: 'jpg',
      version: 1664176834,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:34Z',
      bytes: 830526,
      width: 1920,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176834/NFT-%20Buildings/NFT_62_Clothing_Store_250_Oxford_Street_London_lpxbws.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176834/NFT-%20Buildings/NFT_62_Clothing_Store_250_Oxford_Street_London_lpxbws.jpg',
    },
    {
      asset_id: '0c8f234f5dc90821cc94ada5b7a2388c',
      public_id: 'NFT- Buildings/NFT_61_Clothing_Store_250_Oxford_Street_London_th5jby',
      format: 'jpg',
      version: 1664176834,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:34Z',
      bytes: 708389,
      width: 1920,
      height: 1080,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176834/NFT-%20Buildings/NFT_61_Clothing_Store_250_Oxford_Street_London_th5jby.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176834/NFT-%20Buildings/NFT_61_Clothing_Store_250_Oxford_Street_London_th5jby.jpg',
    },
    {
      asset_id: 'ecf6ab7107d1539c187bf85e6fc2f77b',
      public_id: 'NFT- Buildings/NFT_10_Oxford_Street_London_yy2lpr',
      format: 'jpg',
      version: 1664176834,
      resource_type: 'image',
      type: 'upload',
      created_at: '2022-09-26T07:20:34Z',
      bytes: 249030,
      width: 1512,
      height: 1600,
      folder: 'NFT- Buildings',
      url: 'http://res.cloudinary.com/gameree/image/upload/v1664176834/NFT-%20Buildings/NFT_10_Oxford_Street_London_yy2lpr.jpg',
      secure_url:
        'https://res.cloudinary.com/gameree/image/upload/v1664176834/NFT-%20Buildings/NFT_10_Oxford_Street_London_yy2lpr.jpg',
    },
    {
      asset_id: '6518f8830c128293e9078ae1a4071edf',
      public_id: 'https://gameree.net/static/media/coinBox.88c4c563a1a3826d7d68.png',
      format: 'png',
      resource_type: 'image',
      type: 'fetch',
      created_at: '2022-09-15T07:31:05Z',
      bytes: 5930869,
      width: 8336,
      height: 8337,
      folder: '',
      url: 'http://res.cloudinary.com/gameree/image/fetch/https://gameree.net/static/media/coinBox.88c4c563a1a3826d7d68.png',
      secure_url:
        'https://res.cloudinary.com/gameree/image/fetch/https://gameree.net/static/media/coinBox.88c4c563a1a3826d7d68.png',
    },
  ],
}
export default Cloudnary

// const nftFiltered = Cloudnary.resources.filter((item) => item.public_id.match('175'))
// console.log(nftFiltered.map((item) => item.url))
