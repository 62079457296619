import { Dialog, DialogContent } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { useEffect, useRef, useState } from 'react'

export default function PreviewImage({ status, toggleModal, image }) {
  const img = useRef('')
  const [height, setHeight] = useState(700)
  const [width, setWidth] = useState(800)
  const [loading, setLoading] = useState(true)
  let add = 50
  function zoomin() {
    setHeight((prevState) => prevState + add)
    setWidth((prevState) => prevState + add)
  }
  function zoomout() {
    setHeight((prevState) => prevState - add)
    setWidth((prevState) => prevState - add)
  }
  useEffect(() => {
    setTimeout(() => setLoading(false), 3000)
  }, [])
  return (
    <>
      <Dialog
        open={status}
        onClose={toggleModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
        className="preview-image-modal"
      >
        <div className="preview-dialog-header">
          <ZoomInIcon onClick={() => zoomin()} />
          <ZoomOutIcon onClick={() => zoomout()} />
          <CloseIcon onClick={() => toggleModal(false)} />
        </div>
        <DialogContent className="primary-scroll">
          <div className="preview-image-main">
            <img
              src={image}
              className=""
              onLoad={({ target: img }) => {}}
              style={{
                height: height,
                width: width,
              }}
              ref={img}
              alt=""
            />

            {/* </div> */}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
