import React, { useCallback, useEffect, useRef, useState } from 'react'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

import { ethers } from 'ethers'

import { NFT_addr } from '../../../contract/addresses'
import ABI from '../../../contract/GameRee1155.json'
import Web3Modal from 'web3modal'

import apis from '../../../services'
import { getTotalAreaRandomArea, getTotalPrice } from '../../../helpers/measurements'
import { BuyDigitalCerfiticate, DigitalCerfiticate } from '../modals'
import MapLoader from '../../MapLoader'
import getBuildingImage from '../../../helpers/getBuildingImage'
import { toast } from 'react-toastify'
import { navigate } from '@reach/router'
import { useEagerConnect, useInactiveListener } from '../../../hooks/useEagerConnect'
import { useSelector } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import UserProfileData from '../modals/UserProfileModal'
import GoogleSearch from './GoogleSearch'
import GamereeAbi from '../../../contract/GameRee1155.json'
import Cloudnary from '../home/cloudnary'
import axios from 'axios'
import FigmaProfileModal from '../modals/FigmaProfileModal'

mapboxgl.accessToken = `pk.eyJ1IjoiZ2FtZXJlZSIsImEiOiJjbDVqaWk3aDUwMGdqM2NxcjZoMGhjanprIn0.vvzASm5oVT3sGtBNakNSQg`
const VIEWS = [
  { view: 'Street View', pitch: 90 },
  { view: 'Drone View', pitch: 60 },
  { view: 'Top View', pitch: 0 },
]

export default function Mapbox() {
  const [address, setAddress] = useState()
  const [, setUserInfo] = useState(false)
  const [hoverData, SetHoverData] = useState()
  const [mapboxData, SetMapBoxData] = useState()

  let [markers, setMarkers] = useState([])
  const [MapNavigate, setMapNavigate] = useState()
  const viewCounter = 2

  const [zoom, setZoom] = useState(18)
  const [open, setOpen] = useState(false)

  const [dimensionViews, setDimViews] = useState(true)

  const [buildingData, setBuilding] = useState()
  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalIsOpenProfile, setIsOpenProfile] = useState(false)
  const [modalIsOpenSearch, setIsOpenSearch] = useState(false)

  const [, setProfileIsOpen] = useState(false)
  const [position, setPosition] = useState({ lat: 0, lng: 0 })
  const [mapLoader, setMapLoader] = useState(true)
  const [refreshMap, setRefreshMap] = useState(false)
  const [, setErrorMsg] = useState(false)

  useEagerConnect(setErrorMsg)
  useInactiveListener()

  const handleClose = () => {
    setOpen(false)
  }

  const mapContainer = useRef('')

  const [lng, setLng] = useState(-0.141099)
  const [lat, setLat] = useState(51.515419)
  const state = useSelector((state) => state.user)

  const [metadata, setMetadata] = useState([])
  const [isMinted, setMinting] = useState(false)

  const { user } = useSelector((store) => store.user)
  const isFetchReady = useRef(true)
  const getAllMintedIds = useCallback(async () => {
    // const {data} = await apis.getAllMintedIds();
    const { data } = await apis.getNFTCategoryIDs()
    return data
  }, [])

  const checkIfWalletIsConnected = async () => {
    try {
      if (typeof window !== 'undefined') {
        const { ethereum } = window

        if (!ethereum) {
          toast.error('Install your wallet')
        }

        const accounts = await ethereum.request({ method: 'eth_accounts' })

        if (accounts !== 0) {
          const account = accounts[0]
          return account
        } else {
          toast.error('Could not find an authorized account')
        }
      }
    } catch (error) {
      toast.error(error)
    }
  }

  const loadProvider = async () => {
    const acc = await checkIfWalletIsConnected()
    try {
      const web3Modal = new Web3Modal()

      if (acc === undefined) {
        toast.error('Connect your wallet')
        await web3Modal.connect()
        return
      } else {
        const connection = await web3Modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        return provider.getSigner()
      }
    } catch (e) {
      console.log('loadProvider: ', e)
    }
  }

  const getMetadata = async (id) => {
    try {
      console.log('getmetadata-start', id)
      let signer = await loadProvider()
      let NFTCrowdsaleContract = new ethers.Contract(NFT_addr, ABI, signer)
      console.log(signer)
      const caccount = await signer.getAddress()
      let uri = await NFTCrowdsaleContract.uri(id)
      console.log(uri)

      let owner
      try {
        owner = await NFTCrowdsaleContract.ownerOf(id)
      } catch (error) {
        owner = '0x0000000000000000000000000000000000000000'
      }
      let response = await fetch(uri, { method: 'GET' })
      const data = await response.json()
      // data.owner = owner
      data.id = id
      data.account = caccount
      console.log(data)
      return data
    } catch (e) {
      console.error('data', e)
    }
  }

  const toggleModal = () => setIsOpen((prevState) => !prevState)

  const toggleModalProfile = () => setIsOpenProfile((prevState) => !prevState)
  const toggleModalSearch = () => setIsOpenSearch((prevState) => !prevState)

  const refreshMapHandler = () => setRefreshMap((prevState) => !prevState)

  const certificateProps = {
    toggleModal,
    cloudnaryData: Cloudnary,
    status: modalIsOpen,
    data: buildingData,
    loading,
    position,
    refreshMapHandler,
    // allNFTPics,
    zoom: zoom,
  }
  const showProfileProps = {
    toggleModalProfile,
    status: modalIsOpenProfile,
    loading,
    refreshMapHandler,
  }
  const showSearchProps = {
    status: modalIsOpenSearch,
    toggleModalSearch,
  }

  useEffect(() => {
    if (!state.user) {
      navigate(`/signIn`)
    }

    setTimeout(() => setMapLoader(false), 5000)

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style:
        dimensionViews === true
          ? 'mapbox://styles/gameree/cl8a2a47r008s14pls1cl9gw6'
          : 'mapbox://styles/gameree/cl8a2av7u002d14rty02ym895',
      width: '100wh',
      attributionControl: false,
      pitch: VIEWS[viewCounter].pitch,
      center: [lng, lat],
      zoom: 18,
      bearing: 80,
      layers: [
        {
          id: 'background',
          type: 'background',
          layout: {},
          paint: {
            'background-color': ['white'],
          },
        },
        {},
      ],
    })

    /**
     * Building Areas Offered
     *
     * */
    class BuildingsPlace {
      constructor({ minpitchzoom = null }) {
        this._minpitchzoom = minpitchzoom
      }

      onAdd(map) {
        this._map = map
        // let _this = this

        this._btn = document.createElement('button')
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-profile'
        this._btn.type = 'button'
        this._btn.innerHTML = `
        <img src="https://image.shutterstock.com/image-vector/area-icon-vector-illustration-260nw-1249102720.jpg"
        style="width: 40px; height: 40px;"
          alt="avatar"
          ></img>
        `
        this._btn['aria-label'] = 'Toggle Pitch'
        this._btn.onclick = function () {
          setOpen(true)
        }

        this._container = document.createElement('div')
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'
        this._container.appendChild(this._btn)

        return this._container
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container)
        this._map = undefined
      }
    }

    /**
     *
     *  Change the Buildings to 2d/3d
     * */
    class TwoThreeDimensions {
      constructor({ minpitchzoom = null }) {
        this._minpitchzoom = minpitchzoom
      }

      onAdd(map) {
        this._map = map
        // let _this = this

        this._btn = document.createElement('button')
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-profile'
        this._btn.type = 'button'
        this._btn.innerHTML = `
        <img src="https://img.freepik.com/free-vector/city-skyline-concept-illustration_114360-8923.jpg?w=2000"
          style="width: 40px; height: 40px;"
          alt="avatar"
          ></img>
        `
        this._btn['aria-label'] = 'Toggle Pitch'
        this._btn.onclick = function () {
          setDimViews(!dimensionViews)
        }

        this._container = document.createElement('div')
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'
        this._container.appendChild(this._btn)

        return this._container
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container)
        this._map = undefined
      }
    }

    /**
     *
     *
     * */

    /**
     *
     *  Profile Component
     * */
    class ProfileComponent {
      constructor({ bearing = -20, pitch = 70, minpitchzoom = null }) {
        this._bearing = bearing
        this._pitch = pitch
        this._minpitchzoom = minpitchzoom
      }

      onAdd(map) {
        this._map = map
        // let _this = this

        this._btn = document.createElement('button')
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-profile'
        this._btn.type = 'button'
        this._btn.innerHTML = `
        <img src="https://res.cloudinary.com/dot-pic/image/upload/v1659617513/Avatar_3_zaxygb.png"
          style="width: 40px; height: 40px;"
          alt="avatar"
          ></img>
        `
        this._btn['aria-label'] = 'Toggle Pitch'
        this._btn.onclick = function () {
          setUserInfo(true)
          setIsOpenProfile(true)
        }

        this._container = document.createElement('div')
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'
        this._container.appendChild(this._btn)

        return this._container
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container)
        this._map = undefined
      }
    }

    /**
     *  View Change Component
     * */
    class PitchToggle {
      constructor({ bearing = -20, pitch = 70, minpitchzoom = null }) {
        this._bearing = bearing
        this._pitch = pitch
        this._minpitchzoom = minpitchzoom
      }

      onAdd(map) {
        this._map = map
        let _this = this

        this._btn = document.createElement('button')
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d'
        this._btn.type = 'button'
        this._btn['aria-label'] = 'Toggle Pitch'
        this._btn.onclick = function () {
          if (map.getPitch() === 0) {
            map.easeTo({ pitch: 60, bearing: 80 })

            _this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d'
          } else {
            map.easeTo({ pitch: 0, bearing: 80 })
            _this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d'
          }
        }

        this._container = document.createElement('div')
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'
        this._container.appendChild(this._btn)

        return this._container
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container)
        this._map = undefined
      }
    }

    class SearchComponent {
      constructor({ minpitchzoom = null }) {
        this._minpitchzoom = minpitchzoom
      }

      onAdd(map) {
        this._map = map
        // let _this = this

        this._btn = document.createElement('button')
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-profile'
        this._btn.type = 'button'
        this._btn.innerHTML = `
        <img src="https://img.icons8.com/dotty/2x/search.png"
        style="width: 40px; height: 40px;"

          alt="avatar"
          ></img>
        `
        this._btn['aria-label'] = 'Toggle Pitch'
        this._btn.onclick = function () {
          setIsOpenSearch(true)
        }

        this._container = document.createElement('div')
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'
        this._container.appendChild(this._btn)

        return this._container
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container)
        this._map = undefined
      }
    }

    const nav = new mapboxgl.NavigationControl()
    map.addControl(nav, 'bottom-right')

    map.addControl(new PitchToggle({ minpitchzoom: 11 }), 'bottom-right')
    map.addControl(new ProfileComponent({ minpitchzoom: 11 }), 'top-right')
    map.addControl(new SearchComponent({ minpitchzoom: 11 }), 'bottom-right')
    map.addControl(new BuildingsPlace({ minpitchzoom: 11 }), 'bottom-right')
    map.addControl(new TwoThreeDimensions({ minpitchzoom: 11 }), 'bottom-right')

    map.on('load', async () => {
      const layer = map.getStyle().layers.at(-1)

      const layerID = layer.id
      const { sale, minted } = await getAllMintedIds()

      console.log('## SALE AND MINTED ##\n', { sale, minted })

      const saleCondition = ['in', ['id'], ['literal', sale]]
      const mintCondition = ['in', ['id'], ['literal', minted]]
      const paint = ['case', saleCondition, '#EB906E', mintCondition, '#808080', '#800080']

      if (dimensionViews === false) {
        map.setPaintProperty(layerID, 'fill-color', paint)
        const el = document.createElement('div')
        el.textContent = 'SFK'
        el.style =
          'color: cyan; font-size: 30px; width: 50px; height: 50px; border-radius: 50%; cursor: pointer; background-size: cover; font-weight: bold; '
        // map.setPaintProperty(layerID, 'fill-extrusion-color', paint)
        new mapboxgl.Marker(el).setLngLat([-0.1484, 51.5148]).addTo(map)
      } else {
        const el = document.createElement('div')
        el.textContent = 'SFK'
        el.style =
          'color: cyan; font-size: 30px; width: 50px; height: 50px; border-radius: 50%; cursor: pointer; background-size: cover; font-weight: bold; '
        map.setPaintProperty(layerID, 'fill-extrusion-color', paint)
        new mapboxgl.Marker(el).setLngLat([-0.1484, 51.5148]).addTo(map)
      }
    })
    map.on('click', async (e) => {
      const point = e.lngLat
      setProfileIsOpen(false)
      var features = map.queryRenderedFeatures(e.point)
      const _id = features[0]?.id
      let buildingLayerId

      if (dimensionViews === false) buildingLayerId = features[0]?.layer?.id
      else buildingLayerId = features[1]?.layer?.id

      if (buildingLayerId === 'C100') {
        const { lat, lng } = e.lngLat
        fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${point.lng},${point.lat}.json?limit=1&access_token=pk.eyJ1IjoiZ2FtZXJlZSIsImEiOiJjbDVqaWk3aDUwMGdqM2NxcjZoMGhjanprIn0.vvzASm5oVT3sGtBNakNSQg`
        )
          .then((data) => data.json())
          .then((json) => {
            // console.log(json)
            SetMapBoxData(json)
          })
          .catch((e) => {
            console.log(e)
          })
        // fetch(
        //   `https://maps.googleapis.com/maps/api/geocode/json?latlng=${point.lat},${point.lng}&key=AIzaSyBxZ5mUOwo3CUldbWrsKCZyeVJVffyP8AU`
        // )
        //   .then((data) => {
        //     return data.json()
        //   })
        //   .then((json) => {
        //     console.log(json.results[0].formatted_address)
        //     axios
        //       .post(`http://localhost:4000/findUniqueAddress`, { address: json.results[0].formatted_address })
        //       .then((res) => {
        //         console.log(res.data[0])
        //         SetMapBoxData(res.data[0])
        //       })
        //   })

        setPosition({ lat, lng })

        setLoading(true)
        setMinting(false)
        const response = await apis.getMapNFTData(_id)
        const mintedId = response?.data?.mintedId || ''

        if (mintedId) {
          // const data = await getMetadata(mintedId)
          // if (!data) {
          //   toast.error('Invalid metadata')
          //   return
          // }
          // setBuilding(data)
          setMinting(true)
          setIsOpen(true)
          setLoading(false)
          return
        }

        const username = user.userName || 'GameRee'
        const area = getTotalAreaRandomArea()
        const total = getTotalPrice(area)
        // const signer = await loadProvider()
        // const GamereeContract = new ethers.Contract(NFT_addr, GamereeAbi, signer)
        // const price = await GamereeContract.getMintingPricePerSquare()
        // const pricePerSquare = ethers.utils.formatEther(price)

        apis
          .getGoogleData(lat, lng)
          .then((res) => res.data)
          .then(async (json) => {
            const row = json.results[0]
            const [building_name, street_name, city] = row.formatted_address.split(',')

            const placeData = {
              id: _id,
              name: 'GameRee',
              building_name,
              street_name,
              city,
              owner: username,
              // postal_code,
              total_units: 'Not Available',
              total_gross_area_sqt: area,
              price_per_square: 90,
              initial_price: total,
              current_nft_price: 0.5,
              description: 'GameRee NFT minted from GameRee website',
              image: await getBuildingImage(_id),
            }
            // console.log(placeData)
            setBuilding(placeData)
            setIsOpen(true)
            setLoading(false)
          })
          .catch((e) => {
            setLoading(false)
          })
      }
    })
    map.on('mousemove', (e) => {
      const point = e.lngLat

      // `e.point` is the x, y coordinates of the `mousemove` event
      // relative to the top-left corner of the map.
      setLng(point.lng)
      setLat(point.lat)

      var features = map.queryRenderedFeatures(e.point)

      /**
       * checks the layer then fetches the building data on the hover on the building on the basis of lng,lat
       * */
      if (features[0]) {
        if (isFetchReady.current !== true) return
        isFetchReady.current = false
        fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${point.lng},${point.lat}.json?limit=1&access_token=pk.eyJ1IjoiZ2FtZXJlZSIsImEiOiJjbDVqaWk3aDUwMGdqM2NxcjZoMGhjanprIn0.vvzASm5oVT3sGtBNakNSQg`
        )
          .then((data) => {
            isFetchReady.current = true
            return data.json()
          })
          .then((json) => {
            SetHoverData(json)
          })
          .catch((e) => {})
      }
    })

    map.on('move', (e) => {
      setLng(map.getCenter().lng)
      setLat(map.getCenter().lat)
      setZoom(map.getZoom().toFixed(4))
    })
    setMapNavigate(map)
    return () => {
      map.remove()
    }
  }, [metadata, refreshMap, dimensionViews])

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Select   Buildings  Areas '}</DialogTitle>
          <DialogContent>
            <List>
              <ListItem
                onClick={() => {
                  MapNavigate.flyTo({
                    pitch: 0,
                    zoom: 18,
                    center: [-0.1304631464227839, 51.51641177208569],
                  })
                  setOpen(false)
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary="1. Oxford Street, London," />
                </ListItemButton>
              </ListItem>

              <ListItem
                onClick={() => {
                  MapNavigate.flyTo({
                    pitch: 0,
                    zoom: 18,
                    center: [-0.14203501935114105, 51.51527717368762],
                  })

                  setOpen(false)
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary="2. Oxford Street, London," />
                </ListItemButton>
              </ListItem>
              <ListItem
                onClick={() => {
                  MapNavigate.flyTo({
                    pitch: 0,
                    zoom: 18,
                    center: [-0.15779513234576825, 51.51350268739273],
                  })
                  setOpen(false)
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary="3. Oxford Street, London," />
                </ListItemButton>
              </ListItem>
            </List>
          </DialogContent>
        </Dialog>

        <div className="container text-center mb-1"></div>
        {/*
        <div className="sidebar_2" style={{}}>
          {' '}
          Longitude: {lng.toFixed(4)} | Latitude: {lat.toFixed(4)} | Zoom: {zoom} {' '}
        </div>

        {hoverData && <div className="sidebar">{`${hoverData?.features[0]?.place_name}`}</div>} */}

        {mapLoader && <MapLoader />}

        <div style={{ height: '100vh', position: 'relative' }} ref={mapContainer} className="map-container">
          <div className="sidebar_2" style={{}}>
            {' '}
            Longitude: {lng.toFixed(4)} | Latitude: {lat.toFixed(4)} | Zoom: {zoom} {/*| Pitch {pitch}*/}{' '}
          </div>

          {hoverData && <div className="sidebar">{`${hoverData?.features[0]?.place_name}`}</div>}
        </div>
      </div>
      {isMinted ? (
        <DigitalCerfiticate {...certificateProps} mapboxData={mapboxData?.features[0]?.place_name} />
      ) : (
        <BuyDigitalCerfiticate {...certificateProps} mapboxData={mapboxData?.features[0]?.place_name} />
      )}
      {modalIsOpenProfile && <FigmaProfileModal {...showProfileProps} />}

      {modalIsOpenSearch && (
        <GoogleSearch
          address={address}
          setAddress={setAddress}
          markers={markers}
          setMarkers={setMarkers}
          MapNavigate={MapNavigate}
          {...showSearchProps}
        />
      )}
    </>
  )
}
