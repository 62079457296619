import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import coin from '../../assets/images/bitcoin.png'
import { Col, Row } from 'react-bootstrap'
import nft from '../../assets/images/collection-nft.png'
import arrow_up from '../../assets/images/arrow_up.png'

const Buy = ({ isOpen, setIsOpen, balance, handleToggle }) => {
  return (
    <>
      <div className={'resell-main primary-scroll ' + (isOpen ? 'resell-show' : '')}>
        <div className="dialog-close-header">
          <CloseIcon onClick={() => setIsOpen(false)} />
        </div>
        <div className="heading-btn">
          <h3>Account Balance</h3>
          <button className="custom-btn primary-btn" onClick={() => handleToggle()}>
            Deposit
          </button>
        </div>
        <div className="property-inner-heading">
          <h4>Wallet Balance</h4>
          <div className="coin-detail">
            <img src={coin} alt="" />
            <span>USDG {balance}</span>
          </div>
        </div>
        <Row className="gy-4">
          <Col lg={4} md={6}>
            <div className="sell-box-layer-1">
              <div className="sell-box-layer-2">
                <div className="sell-box">
                  <img src={nft} className="nft-img" alt="" />
                  <img src={arrow_up} className="nft-profile" alt="" />
                </div>
                <div className="sell-box-body">
                  <h3>McDonald’s</h3>
                  <h4>291B Oxford St, W1C 2DT, UK</h4>
                  <div className="sell-flex-price">
                    <p>3h 1m 10s</p>
                    <p>0.15 BTC</p>
                  </div>
                </div>
                <div className="buttons mainside nft-footer w-100">
                  <button className="d-block" style={{ color: 'white' }}>
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="sell-box-layer-1">
              <div className="sell-box-layer-2">
                <div className="sell-box">
                  <img src={nft} className="nft-img" alt="" />
                  <img src={arrow_up} className="nft-profile" alt="" />
                </div>
                <div className="sell-box-body">
                  <h3>McDonald’s</h3>
                  <h4>291B Oxford St, W1C 2DT, UK</h4>
                  <div className="sell-flex-price">
                    <p>3h 1m 10s</p>
                    <p>0.15 BTC</p>
                  </div>
                </div>
                <div className="buttons mainside nft-footer w-100">
                  <button className="d-block" style={{ color: 'white' }}>
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="sell-box-layer-1">
              <div className="sell-box-layer-2">
                <div className="sell-box">
                  <img src={nft} className="nft-img" alt="" />
                  <img src={arrow_up} className="nft-profile" alt="" />
                </div>
                <div className="sell-box-body">
                  <h3>McDonald’s</h3>
                  <h4>291B Oxford St, W1C 2DT, UK</h4>
                  <div className="sell-flex-price">
                    <p>3h 1m 10s</p>
                    <p>0.15 BTC</p>
                  </div>
                </div>
                <div className="buttons mainside nft-footer w-100">
                  <button className="d-block" style={{ color: 'white' }}>
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Buy
